<template>
	<div>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		props: {
			cluster: { type: Object, reuired: true },
			// lat: { type: Number, required: true },
			// lng: { type: Number, required: true }
		},
		data: () => ({
			infoW: null
		}),
		methods: {
			closeWindow: function () {
				this.infoW.close()
			},
			openWindow: function (map) {
				this.infoW.open(map)
			},
			changePosition: function (position) {
				console.log(position)
				this.infoW.setPosition(position)
			}
			// getWindow: function () {
			// 	console.log(this.cluster)
			// this.$parent.getMap(map => {
			// 	(
					
			// 	this.infoW = new InfoBox({
			// 		position: this.cluster.getCenter(),
			// 		disableAutoPan: false,
			// 		maxWidth: 0,
			// 		pixelOffset: new google.maps.Size(-140, 5),
			// 		zIndex: null,
			// 		boxStyle: {
			// 			padding: "0px",
			// 			width: "295px",
			// 		},
			// 		closeBoxURL: "",
			// 		infoBoxClearance: new google.maps.Size(1, 1),
			// 		isHidden: false,
			// 		pane: "floatPane",
			// 		enableEventPropagation: false,
			// 		content: this.$el
			// 	})),
			// 	this.infoW.open(map)
			// })
			// }
		},
		// mounted() {
		// 	this.$parent.getMap(map => {
		// 		(this.infoW = new window.google.maps.InfoWindow({
		// 			position: { lat: this.lat, lng: this.lng },
		// 			content: this.$el,
		// 			disableAutoPan: true
		// 		})),
		// 			this.infoW.open(map)
		// 	})
		// },
		mounted () {


// console.log(this.cluster)
			this.$parent.getMap(map => {
				(
					
				this.infoW = new InfoBox({
					position: this.cluster.getCenter(),
					disableAutoPan: false,
					maxWidth: 0,
					pixelOffset: new google.maps.Size(-140, 5),
					zIndex: null,
					boxStyle: {
						padding: "0px",
						width: "295px",
					},
					closeBoxURL: "",
					infoBoxClearance: new google.maps.Size(1, 1),
					isHidden: false,
					pane: "floatPane",
					enableEventPropagation: false,
					content: this.$el
				})),
				this.infoW.open(map)
			})

	
			
		},
		// beforeDestroy() {
		// 	this.infoW.close()
		// }
	}
</script>
<style scoped>

</style>