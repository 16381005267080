<template>
  <div class="row">
        <div v-for="(item, index) in filters" :key="index" :class="{'col-12': item.col == 'col-12', 'col-6': item.col == 'col-6'}">
              <div v-if="item.type == 'input' && !item.heatmap_only">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                <input :type="item.input_type" :placeholder="item.placeholder" class="form-control" v-model="inputForm[item.vmodel]" />
              </div>
              <div v-if="item.type == 'textarea'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                <textarea rows="5" :placeholder="item.placeholder" class="form-control" v-model="inputForm[item.vmodel]" ></textarea>
                <!-- <input :type="item.input_type" :placeholder="item.placeholder" class="form-control" v-model="inputForm[item.vmodel]" /> -->
              </div>
              <div v-if="item.type == 'dropdown'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                <small v-if="item.note">{{item.note}}</small>
                <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue">
                  <template slot="singleLabel" slot-scope="{ option }">
                  <span style="color:#009370;">&nbsp;{{ option }}</span>
      </template>
                </multiselect>
              </div>
              <div v-if="item.type == 'dropdown-state'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                <multiselect :options="item.data" track-by="abbreviationn" label="abbreviation" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
              </div>
              <div v-if="item.type == 'dropdown-specialty' && accessType == 'all'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
              </div>
              <!-- <div v-if="item.type == 'dropdown-indication' && accessType == 'all'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
              </div> -->
              <div v-if="item.type == 'dropdown-indication'">
                <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;">{{item.label}}</h4>
                <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
              </div>
              <div v-if="item.type == 'radio-btn'">
                <h4 style="font-weight: 400; font-size: 14px; margin-top: 10px; margin-bottom: 5px;">{{item.label}}</h4>
                <!-- <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                  <label v-for="(rb, rbIndex) in item.data" :key="rbIndex" class="btn btn-primary">
                    <input type="radio" :value="rb" v-model="inputForm[item.vmodel]" @click="checkItem(item, rb)"> {{rb}}
                  </label>
                </div> -->
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    class="w-100"
                    id="btn-radios-1"
                    v-model="inputForm[item.vmodel]"
                    :options="item.data"
                    :aria-describedby="ariaDescribedby"
                    name="radios-btn-default"
                    button-variant="primary"
                    @change="checkedItem(item)"
                    buttons
                  ></b-form-radio-group>
                </b-form-group>
              </div>
              <div v-if="item.type == 'radio-btn-indication' && item.molecule_type.includes(accessType)">
                <h4 style="font-weight: 400; font-size: 14px; margin-top: 10px; margin-bottom: 5px;">{{item.label}}</h4>
                <!-- <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                  <label v-for="(rb, rbIndex) in item.data" :key="rbIndex" class="btn btn-primary">
                    <input type="radio" :value="rb" v-model="inputForm[item.vmodel]" @click="checkItem(item, rb)"> {{rb}}
                  </label>
                </div> -->
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    class="w-100"
                    id="btn-radios-2"
                    v-model="inputForm[item.vmodel]"
                    :options="item.data"
                    :aria-describedby="ariaDescribedby"
                    name="radios-btn-default"
                    button-variant="primary"
                    @change="checkedItem(item)"
                    buttons
                  ></b-form-radio-group>
                </b-form-group>
              </div>
              <div v-if="item.type == 'checkbox'" style="margin-top: 15px;">
                <h4 style="font-weight: 400; font-size: 14px; margin-top: 10px; margin-bottom: 5px;">{{item.label}}</h4>
                <div class="form-check" v-for="(cb, cbIndex) in item.data" :key="cbIndex">
                  <input class="form-check-input" type="checkbox" :value="rb" v-model="inputForm[item.vmodel]" @change="checkedItem(item)">
                  <label class="form-check-label">
                    {{cb}}
                  </label>
                </div>
              </div>
              <div style="margin-left: 0px; margin-top: 15px;" v-if="item.type == 'toggle-btn'">
                <div class="d-flex justify-content-between">
                  <h4 style="text-transform: uppercase; font-size: 14px;">{{item.label}}</h4>
                  <font-awesome-icon v-if="item.has_info_icon" :id="'popover-target-'+item.id" icon="info-circle" style="cursor:pointer; font-size: 20px; color:#006bb6;"/>
                  <b-popover v-if="item.has_info_icon" :target="'popover-target-'+item.id" triggers="click" placement="right" boundary="window">
                    <template #title>{{item.info_details.title}}</template>
                    {{item.info_details.content}}
                  </b-popover>
                </div>
                <label class="switch">
                    <input type="checkbox" v-model="inputForm[item.vmodel]" @change="checkedItem(item)">
                    <div class="slider round"></div>
                </label>
              </div>
           
          </div>
        </div>

</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
export default {
  name: 'ProfileForm',
  props: {
    filters: Array,
    formData: Object
  },
  data() {
    return {
      accordions: [],
      dpvmodel: '',
      inputForm: {},
      accessType: localStorage.getItem('access_type')
    }
  },
  methods: {
    toggleAccordion: function(index, type) {
      if(this.accordions[index].type === type) {
        this.accordions[index].toggle = !this.accordions[index].toggle
        if(this.accordions[index].toggle === true) {
          this.accordions[index].icon = 'chevron-down'
        } else {
          this.accordions[index].icon = 'chevron-right'
        }
      }
    },
    checkItem: function(item, value) {
      this.inputForm[item.vmodel] = value
      if(this.formData) {
        this.resetData(item)
      } else {
        this.$emit('filteredItems', this.inputForm)
      }
      // this.filterData()
    },

    checkedItem: function (item) {
      // console.log(item)
      // console.log(this.inputForm[item.vmodel])
      // console.log(this.inputForm)
      this.$emit('filteredItems', this.inputForm)
    },
    getSelected: function(item) {
      this.dpvmodel = item.vmodel
    },
    getValue: function(action) {
      this.inputForm[this.dpvmodel] = action
      // this.filterData()
      this.$emit('filteredItems', this.inputForm)
    },
    resetFilters: function () {
      this.inputForm = {}
      this.$emit('filteredItems', this.inputForm)
    },
    isEmptyObject: function isObjectEmpty(obj) {
        let isEmpty = true
        forEach(obj, item => {
          if (item !== null) {
            isEmpty = false
          }
        })
        return isEmpty
    },
    downloadExcel: function () {
      let data = '';
      // console.log(this.isEmptyObject(this.inputForm))
      // console.log(this.inputForm)
      if(!this.isEmptyObject(this.inputForm)){
          let items = {}
          forEach(this.inputForm, (item, key) => {
            if(item !== null) {
              items[key] = item
            }
          })
          // console.log(items)
          data = JSON.stringify(items);
      }else{
          data = "all"  
      }
      if(this.accessType) {
        window.location.href = `${this.excel}?type=${this.accessType}&data=${data}`
      } else {
        window.location.href = `${this.excel}?data=all`
      }
      
    }
    // filterData: function() {
    //   axios.post(`${this.url}kols/filter`, JSON.stringify(this.inputForm)).then(res => {
    //      console.log(res.data)
    //      this.$emit('filteredItems', res.data)
    //   })
    // }
  },
  mounted () {
    console.log(this.formData)
    // console.log(this.resetData)
    // console.log('filter', this.filters)
    // this.accordions = this.filters
    // let data = {}
    // forEach(this.accordions, fields => {
    //   forEach(fields.fields, item => {
    //     data[item.vmodel] = null
    //   })
    // })
    // console.log(data)
    this.inputForm = this.formData
    // console.log(this.accordions)
  }
}
</script>
<style scoped>
/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

input, select, textarea {
    color: #009370;
}


</style>
