<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title">{{title}}</h5>
            <div class="d-flex">
              <button class="btn btn-secondary" @click="close">Close</button>
            </div>
          </div>
          <div class="modal-body p-3" style="padding: 0px; text-align:left;">
            <div class="pb-4" v-for="(item, index) in events" :key="index">
              <div class="row">
                <div class="col-12">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <b-avatar :text="getInitials(item.created_by)" class="mr-3"></b-avatar>
                      <span class="mr-auto" style="font-weight: bold;">{{item.created_by}}</span>
                    </div>
                    <timeago :datetime="item.created_at" :auto-update="60"></timeago>
                  </div>
                  
                  <div style="margin-left: 15px; margin-top: 15px; white-space: pre-wrap;">{{ item.comment }}</div>  
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="flex-wrap: nowrap;">
              <b-form-textarea
                placeholder="Enter comment and press send"
                rows="1"
                max-rows="8"
                v-model="comment"
              ></b-form-textarea>
              <div class="d-flex">
                <button class="btn btn-secondary" @click="submitComment">Send</button>
              </div>
         
           
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
import { http, authHeader } from '@/config/base-url'
export default {
  name: 'InteractionCompletedModal',
  props: ['id', 'convention'],
  data () {
    return {
      events: [],
      title: '',
      comment: ''
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    getInitials:  function (string) {
      var names = string.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();
      
      if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    getInteractionComments: function () {
      http.get(`v2/conventions/interactions/${this.id}/comments`, {
        headers: authHeader
      }).then((res) =>{
        console.log(res.data)
        this.events = res.data
      })
    },
    submitComment: function () {
      const data = {
        convention_id: this.convention,
        comment: this.comment
      }

      http.post(`v2/conventions/interactions/${this.id}/comments`, data, {
        headers: authHeader
      }).then((res) => {
        this.comment = ''
        this.getInteractionComments()
      })
    }
    
    
  },
  mounted () {
    this.title = 'Comments'
    this.getInteractionComments()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vdp-datepicker input.form-control {
  background-color:#FFF;
}
</style>
<style scoped>

 .modal-mask {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-body {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.modal-content {
  height: 500px;
}

.modal-title {
  font-size: 1.5em;
  padding-top: 5px;
}

.close:focus {
  outline: none;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.custom__tag {
      display: inline-block;
    padding: 3px 12px;
    background: #f1f1f1;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
}

.option__title {
  padding-left: 5px;
}

.custom__remove {
  padding-left: 5px;
  cursor: pointer;
}

.multiselect__clear {
    position: absolute;
    right: 1px;
    padding-top: 8px;
    height: 50px;
    width: 50px;
    display: block;
    cursor: pointer;
    z-index: 2;
    color: #ccc;
}
</style>
