<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 600px;">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title text-left">Location Details</h5>
            <div class="d-flex">
              <button class="btn btn-secondary" @click="close">Close</button>
            </div>
          </div>
          <div class="modal-body px-3 pb-4" style="padding: 0px; text-align:left;">
            <div class="row" style="background-color:rgba(0, 0, 0, 0.03);">
              <div class="col px-0">
                <iframe width="600" height="500" frameborder="0" style="border:0" :src="this.location.locationUrl"></iframe>
              </div>
            </div>
            <div class="row py-3" style="background-color:rgba(0, 0, 0, 0.03); border-top: 1px solid #f1f1f1; border-bottom: 1px solid #f1f1f1;">
              <div class="col">
                <div class="d-flex justify-content-between">
                  <div style="color:goldenrod;"><font-awesome-icon v-for="(item, index) in location.priceCount" :key="index" icon="dollar-sign"/></div>
                  <div><font-awesome-icon icon="utensils"/> <span style="padding-left: 10px;">{{location.food_type}}</span></div>
                  <div>Takes Reservation: <span class="badge" :class="{ 'badge-success': location.takes_reservations == true, 'badge-danger': location.takes_reservations == false }">{{location.takes_reservations ? 'Yes' : 'No'}}</span></div>
                  <div><font-awesome-icon icon="compass"/> <span style="padding-left: 10px;">{{location.distance}} Miles</span></div>
                </div>
              </div>
            </div>
            <div class="row py-2">
              <div class="col">
                <h4 style="font-weight: 700;">{{location.name}}</h4>
              </div>
            </div>
            <div class="row py-2">
              <div class="col">
                <h5 style="font-weight: 700;">Address</h5>
                <address>
                  <span v-html="getAddress(this.location.address)"></span>
                  <abbr title="Phone">P:</abbr> {{this.location.phone}}<br>
                  <a :href="this.location.website" target="_blank">{{this.location.website}}</a>
                </address>
              </div>
            </div>
            <div class="row py-2" v-if="location.hours">
              <div class="col">
                <h5 style="font-weight: 700;">Hours</h5>
                <div v-html="getHours(location.hours)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
import { http, authHeader } from '@/config/base-url'
export default {
  name: 'LocationModal',
  props: ['locationId'],
  data () {
    return {
      location: {}
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    getLocationDetail: function() {
      console.log(this.locationId)
      http.get(`v2/conventions/location/${this.locationId}`, {
        headers: authHeader
      }).then((res) => {
        // console.log(res.data)
        res.data.locationUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAuusoi8EzmW2Kh63KgWXhVXXKaAAdbgvU&q=1 ${res.data.address.address_1}, ${res.data.address.city}, ${res.data.address.state}, ${res.data.address.zip}`
        res.data.priceCount = res.data.price.split("$").length-1;
        console.log(res.data)
        this.location = res.data
      })
    },
    getHours: function(hours) { 
      if(hours) {
        console.log(hours)
        let combinedString = ''
        const hourArray = hours[0].split(';')
        hourArray.forEach((item) => {
          combinedString += `${item} <br>`;
        })

        return combinedString
      }
    },
    getAddress: function(address) {
      if(address) {
        return `${address['address_1']}<br>
              ${address.city}, ${address.state} ${address.zip}<br>`
      }
    }
    
  },
  mounted () {
    console.log(this.locationId)
    this.getLocationDetail()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vdp-datepicker input.form-control {
  background-color:#FFF;
}
</style>
<style scoped>

.modal-mask {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-content {
  height: calc(100vh - 60px);
}

.modal-body {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.modal-title {
  font-size: 1.5em;
  padding-top: 5px;
}


.close:focus {
  outline: none;
}

.list-item {
  padding-left: 16px;
  margin-top: 8px;
}

.list-item > h2 {
  font-size: 22px;
  margin-bottom: 0px;
}

.list-item > h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.list-item > p {
  font-size: 14px;
  margin-bottom: 0px;
}

.list-img {
  margin-top: 5px
}

iframe {
  width: 100% !important;
  height: 300px !important;
}
</style>
