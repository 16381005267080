import { http, authHeader } from '@/config/base-url'

export default {
  getUserConventions (publicId) {
    return http.get(`v2/conventions/user/${publicId}`, {
      headers: authHeader
    }).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      console.log(error)
    })
  }
}
