<template>
	<div>
		<div class="d-flex justify-content-between">
			<div class="info-box-position">
				<div class="info-box-wrap d-flex justify-content-between" :style="{ width: width + 'px' }">
					<div class="mySlides" style="padding: 5px;">
						<div class="info-box-text-wrap-sites" :style="{ height: height + 'px' }">
							 <div v-html="html"></div>
							<!-- <h4>{{customMarker.hospital_name}}</h4>
							<h6 class="address">{{customMarker.city}}, {{customMarker.state}} {{customMarker.zip}}</h6> -->
						</div> 
					</div>
					<div class="close-btn box-close" @click="closeWindow">
						<font-awesome-icon icon="times-circle" style="font-size: 20px;"/>
					</div>
				</div>
      		</div>    
     	</div>
	</div>
</template>

<script>
	export default {
		props: {
			// cluster: { type: Object, reuired: true },
			customMarker: { type: Object, required: true },
			lat: { type: String, required: true },
			lng: { type: String, required: true },
			html: { type: String, required: true },
			height: { type: String, required: true },
			width: { type: String, required: true }
		},
		data: () => ({
			infoW: null
		}),
		methods: {
			closeWindow: function () {
				this.infoW.close()
				this.$emit('currentMarkerStatus')
			},
			openWindow: function (map) {
				this.infoW.open(map)
			},
			changePosition: function (position) {
				console.log(position)
				this.infoW.setPosition(position)
			}
			// getWindow: function () {
			// 	console.log(this.cluster)
			// this.$parent.getMap(map => {
			// 	(
					
			// 	this.infoW = new InfoBox({
			// 		position: this.cluster.getCenter(),
			// 		disableAutoPan: false,
			// 		maxWidth: 0,
			// 		pixelOffset: new google.maps.Size(-140, 5),
			// 		zIndex: null,
			// 		boxStyle: {
			// 			padding: "0px",
			// 			width: "295px",
			// 		},
			// 		closeBoxURL: "",
			// 		infoBoxClearance: new google.maps.Size(1, 1),
			// 		isHidden: false,
			// 		pane: "floatPane",
			// 		enableEventPropagation: false,
			// 		content: this.$el
			// 	})),
			// 	this.infoW.open(map)
			// })
			// }
		},
		// mounted() {
		// 	this.$parent.getMap(map => {
		// 		(this.infoW = new window.google.maps.InfoWindow({
		// 			position: { lat: this.lat, lng: this.lng },
		// 			content: this.$el,
		// 			disableAutoPan: true
		// 		})),
		// 			this.infoW.open(map)
		// 	})
		// },
		mounted () {


// console.log(this.cluster)
			this.$parent.getMap(map => {
				(
					
				this.infoW = new InfoBox({
					position: new google.maps.LatLng(this.lat, this.lng),
					disableAutoPan: false,
					maxWidth: 0,
					pixelOffset: new google.maps.Size(-140, 0),
					zIndex: null,
					boxStyle: {
						padding: "0px",
						width: "295px",
					},
					closeBoxURL: "",
					infoBoxClearance: new google.maps.Size(1, 1),
					isHidden: false,
					pane: "floatPane",
					enableEventPropagation: false,
					content: this.$el
				})),
				this.infoW.open(map)
			})

	
			
		},
		// beforeDestroy() {
		// 	this.infoW.close()
		// }
	}
</script>
<style scoped>

</style>