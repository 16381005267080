<template>
  <div>
    <div class="text-left">
      <div style="padding-right: 0px; padding-bottom:10px; font-weight: bold; text-transform:uppercase; cursor:pointer;" @click="resetFilters">Reset Filters</div>  
      <div v-for="(accordion, index) in accordions" :key="index">
        <div class="d-flex justify-content-between" @click="toggleAccordion(index, accordion.type)" style="padding: 0px; cursor: pointer;" data-toggle="collapse" :href="'#'+accordion.type_id">
          <h3 class="text-muted" style="font-size: 16px;">{{accordion.type}}</h3>
          <font-awesome-icon :icon="accordion.icon"/>
        </div>
        <div class="collapse" :id="accordion.type_id" style="padding-bottom: 15px;">
          <div v-for="item in accordion.fields" :key="item.id">
            <div v-if="item.type == 'input' && item.heatmap_only">
              <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
              <input :type="item.input_type" :placeholder="item.placeholder" class="form-control" v-model="search[item.vmodel]" v-on:keyup="filterInput(item)" />
            </div>
            <div v-if="item.type == 'dropdown'">
              <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
              <small v-if="item.note">{{item.note}}</small>
              <multiselect :options="item.data" :allow-empty="true" :multiple="item.multiple" :show-labels="false" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
            </div>
            <div v-if="item.type == 'dropdown-state' && inputForm['country'] == 'United States'">
              <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
              <multiselect :options="item.data" track-by="name" label="name" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
            </div>
            <div v-if="item.type == 'dropdown-specialty' && accessType == 'all'">
              <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
              <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
            </div>
            <div v-if="item.type == 'dropdown-indication'">
              <h4 style="font-weight: 400; font-size: 14px; margin-bottom: 5px; margin-top:10px;"><span v-if="accordion.show_field_id" class="badge badge-primary">{{item.id}}</span> {{item.label}}</h4>
              <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :multiple="item.multiple" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]" @select="getSelected(item)" @input="getValue"></multiselect>
            </div>
            <div v-if="item.type == 'radio-btn'">
              <h4 style="font-weight: 400; font-size: 14px; margin-top: 10px; margin-bottom: 5px;">{{item.label}}</h4>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  class="w-100"
                  id="btn-radios-1"
                  v-model="inputForm[item.vmodel]"
                  :options="item.data"
                  :aria-describedby="ariaDescribedby"
                  name="radios-btn-default"
                  button-variant="primary"
                  @change="checkedItem(item)"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
            </div>
            <div v-if="item.type == 'checkbox'" style="margin-top: 15px;">
              <h4 style="font-weight: 400; font-size: 14px; margin-top: 10px; margin-bottom: 5px;">{{item.label}}</h4>
              <div class="form-check" v-for="(cb, cbIndex) in item.data" :key="cbIndex">
                <input class="form-check-input" type="checkbox" :value="rb" v-model="inputForm[item.vmodel]" @change="checkedItem(item)">
                <label class="form-check-label">
                  {{cb}}
                </label>
              </div>
            </div>
            <div style="margin-left: 0px; margin-top: 15px;" v-if="item.type == 'toggle-btn'">
              <div class="d-flex justify-content-between">
                <h4 style="text-transform: uppercase; font-size: 14px;">{{item.label}}</h4>
                <font-awesome-icon v-if="item.has_info_icon" :id="'popover-target-'+item.id" icon="info-circle" style="cursor:pointer; font-size: 20px; color:#006bb6;"/>
                <b-popover v-if="item.has_info_icon" :target="'popover-target-'+item.id" triggers="click" placement="right" boundary="window">
                  <template #title>{{item.info_details.title}}</template>
                  {{item.info_details.content}}
                </b-popover>
              </div>
              <label class="switch">
                  <input type="checkbox" v-model="inputForm[item.vmodel]" @change="checkedItem(item)">
                  <div class="slider round"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
export default {
  name: 'HeatmapFilters',
  props: {
    filters: Object,
    url: String,
    formData: Object,
    resetData: Boolean
  },
  data() {
    return {
      accordions: [],
      dpvmodel: '',
      inputForm: {},
      search: {},
      accessType: localStorage.getItem('access_type')
    }
  },
  methods: {
    toggleAccordion: function(index, type) {
      if(this.accordions[index].type === type) {
        this.accordions[index].toggle = !this.accordions[index].toggle
        if(this.accordions[index].toggle === true) {
          this.accordions[index].icon = 'chevron-down'
        } else {
          this.accordions[index].icon = 'chevron-right'
        }
      }
    },
    checkItem: function(item, value) {
      this.inputForm[item.vmodel] = value
      if(this.formData) {
        this.resetData(item)
      } else {
        this.$emit('filteredItems', this.inputForm)
      }
      // this.filterData()
    },

    checkedItem: function (item) {
      console.log(item)
      console.log(this.inputForm[item.vmodel])
      console.log(this.inputForm)
      this.$emit('filteredItems', this.inputForm)
    },
    getSelected: function(item) {
      this.dpvmodel = item.vmodel
    },
    getValue: function(action) {
      this.inputForm[this.dpvmodel] = action
      // this.filterData()
      this.$emit('filteredItems', this.inputForm)
    },
    resetFilters: function () {
      this.inputForm = {}
      this.search = {}
      this.$emit('filteredItems', this.inputForm)
    },
    filterInput: function (item) {
      this.$emit('searchName', this.search)
    }
  },
  mounted () {
    console.log(this.resetData)
    this.accordions = this.filters.filters
    let data = {}
    forEach(this.accordions, fields => {
      fields.icon = "chevron-right"
      fields.toggle = false
      forEach(fields.fields, item => {
        data[item.vmodel] = null
      })
    })
    console.log(data)
    this.inputForm = data
    console.log(this.accordions)
  }
}
</script>
<style scoped>
/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
