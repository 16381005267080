<template>
  <div class="login__settings">
    <div class="login">
      <div class="login__header">
        <img class="header__logo" src="@/assets/3connex-logo.svg">
        <span class="header__text" :class="{'header__text__error': hasError, 'header__text__success': hasSuccess}">{{headerText}}</span>
      </div>
      <div class="login__main">
        <p style="padding: 10px 15px 0px 15px; text-align:left;">
          Please provide your email address to reset your password.
        </p>
        <form @submit.prevent="checkForm" novalidate="true">
          <div class="login__main__form">
            <div class="form__group">
              <label class="form__label" for="email">Email Address</label>
              <input type="email" class="form__control" placeholder="Email Address" v-model="user.email">
              <span class="form__validation">{{emailError[0]}}</span>
            </div>
          </div>
          <div class="login__button">
            <input type="submit" value="Submit" class="signin-btn" />
          </div>
        </form>
        <div class="login__button" v-if="hasSuccess">
          <button class="signin-btn" @click="goBack">Back to Sign in</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'

export default {
  name: 'forgot-password',
  data() {
    return {
      user: { email: '' },
      emailError: [],
      headerText: 'Forgot your password?',
      hasError: false,
      hasSuccess: false
    }
  },
  methods: {
    checkForm: function (e) {
      const auth = new AuthService()

      this.emailError = []

      if (!this.user.email) {
        this.emailError.push('Email required')
      } else if (!this.validEmail(this.user.email)) {
        this.emailError.push('Valid email required')
      }

      if (!this.emailError.length) {
        auth.forgotPassword(this.user).then((data) => {
          console.log(data)
          if (data.status === 401) {
            this.hasError = true
            this.hasSuccess = false
            this.headerText = data.data.message
          } else {
            this.user.email = ''
            this.hasSuccess = true
            this.hasError = false
            this.headerText = data.data.message
          }
        })
      }
      e.preventDefault()
    },

    goBack: function () {
      this.$router.go(-1)
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}


// const emailError = ref([])
// const user = reactive({ email: '' })
// const auth = new AuthService()
// const router = useRouter()
// const headerText = ref('Forgot your password?')
// const hasError = ref(false)
// const hasSuccess = ref(false)

// const goBack = () => {
//   router.go(-1)
// }

// const checkForm = (e) => {
//   emailError.value = []

//   if (!user.email) {
//     emailError.value.push('Email required')
//   } else if (!validEmail(user.email)) {
//     emailError.value.push('Valid email required')
//   }

//   if (!emailError.value.length) {
//     auth.forgotPassword(user).then((data) => {
//       console.log(data)
//       if (data.status === 401) {
//         hasError.value = true
//         hasSuccess.value = false
//         headerText.value = data.data.message
//       } else {
//         user.email = ''
//         hasSuccess.value = true
//         hasError.value = false
//         headerText.value = data.data.message
//       }
//     })
//   }

//   e.preventDefault()
// }

// const validEmail = (email) => {
//   var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//   return re.test(email)
// }

</script>

<style scoped>
.login__settings {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EDF1F3;
}

.login {
  width: 320px;
  height: 332px;
  background-color: #fff;
  border-radius: 4px;
}

.login__header {
  height: 50px;
  width: 100%;
  background-color: rgb(248, 250, 252);
  border-bottom: 1px solid rgb(248, 250, 252);
  display: flex;
  flex-direction: column;
}

.header__logo {
  height: 120px;
  margin-top: -120px;
}

.header__text {
  margin: 8px 0;
  font-size: 18px;
}

.header__text__error {
  color: #d9534f;
}

.header__text__success {
  color: #5cb85c;
  font-size: 14px;
}

.login__main {
    width: 100%;
    height: calc(332px - 50px);
}

.login__forgot {
  font-size: 12px;
  padding: 10px 0px;
}

.login__forgot a {
  text-decoration: none;
  color: #000;
}

.login__main__form {
    padding: 0px 15px;
    text-align: left;
}

.form__group {
    padding: 10px 0px 0px 0px;
}

.form__label {
    display: inline-block;
    font-size: 12px;
    color: #7f8fa4;
    font-weight: 300;
    margin-bottom: 0;
}

.form__control {
    width: 100%;
    border-radius: 4px;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    font-weight: 400;
    padding: 5px 12px;
    outline: none;
    display: block;
    font-size: 14px;
}

.form__validation {
  font-size: 12px;
  display: inline-block;
  color: #d9534f;
  font-weight: 300;
}

.login__button {
    padding: 0px 15px 15px 15px;
}

.signin-btn {
    width: 100%;
    background-color: #006BB6;
    color: #fff;
    height: 40px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

/* Switch */
.switch-text {
  margin-right: 15px;
  height: 18px;
  vertical-align: super;
  display:inline-block;
  font-size: 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #009370;
}

input:focus + .slider {
  box-shadow: 0 0 1px #009370;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>
