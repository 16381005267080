<template>
  <div>
    <div id="map"></div>
    <div class="expandpanel">
      <a href=# id="revealSidePanel" style="color: #333;" data-toggle="tooltip" @click="show = !show"><font-awesome-icon icon="angle-double-right"/></a>     
    </div>
    <transition name="slide">
      <ul v-if="show" class="list-group map-menu transform">
        <li class="list-group-item" style="color: #fff; background-color: #006bb6; border-radius: 0px;">
          <div class="d-flex justify-content-between">
            <div style="text-transform: uppercase; font-weight: bold; padding-left: 0px;">Heatmap Layers</div>
            <a href=# id="collapseSidePanel" style="padding-top: 2px; padding-right: 0px; color: #fff;" data-toggle="tooltip" @click="show = !show"><font-awesome-icon icon="angle-double-left"/></a>  
          </div>
        </li>
        <li v-for="item in clientData.heatmap_layers" :key="item.id" class="list-group-item" style="padding: 0px 10px;">
          <div class="d-flex justify-content-between" style="padding-top: 5px;">
            <div v-if="clientData.client != 'AstraZeneca' && clientData.client != 'Leo Pharma'" :class="item.icon">{{item.name}}</div>
            <div v-else :class="item.icon" class="az-icon" v-html="item.name"></div>
            <label class="switch switch-padding">
                <input type="checkbox" v-model="heatmapSelection[item.vmodel]" @change="handle_function_call(item.func_name, item, $event)">
                <div class="slider round" :class="item.color"></div>
            </label>
          </div>
          <heatmap-filters v-if="showKOLFilters && clientData.filter_form.show_in_heatmap && item.vmodel == 'kol'" :filters="clientData.filter_form" :url="clientData.project_url" v-on:filteredItems="filteredItems" v-on:searchName="searchName"></heatmap-filters>
          <custom-heatmap-filters v-if="hasCustomFilters && item.filters" :filters="item.filters" :url="clientData.project_url" v-on:filteredItems="customFilteredItems"></custom-heatmap-filters>
        </li>
      </ul>
    </transition>

    <div class="info-card" v-if="showCard">
      <transition-group name="fade" tag="div">
      <div v-for="i in [currentIndex]" :key="i">
        <div class="d-flex justify-content-between p-3">
          <font-awesome-icon icon="times-circle" @click="showCard = false" style="cursor:pointer;"/>
          <div class="d-flex" v-if="kols.length > 1">
            <font-awesome-icon icon="chevron-left" class="mr-2" @click="prev" style="currsor: pointer;"/>
            <div style="margin-top: -2px; padding-right: 8px; font-size: 14px;">{{currentIndex+1}} of {{kols.length}}</div>
            <font-awesome-icon icon="chevron-right" @click="next" style="currsor: pointer;"/>
          </div>
        </div>
        <div class="profile-image" :style="{backgroundImage:`url(${clientData.kol_image_url}${getImage(currentKOL)})`}"></div>
        <h2 v-if="currentKOL.degree" style="font-size: 18px; font-weight: bold;">{{currentKOL.first_name}} {{currentKOL.last_name}}, {{currentKOL.degree}}</h2>
        <h2 v-else="currentKOL.degree" style="font-size: 18px; font-weight: bold;">{{currentKOL.first_name}} {{currentKOL.last_name}}</h2>
        <h4 class="text-muted px-2" style="font-size: 16px;">{{currentKOL.specialty}}</h4>
        <div>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div class="text-left p-4">
                <h5 style="font-size:16px;">Address</h5>
                <address v-if="currentKOL.hasOwnProperty('address_1')">
                  {{currentKOL.address_1}}<br>
                  {{currentKOL.city}}, {{currentKOL.state}} {{currentKOL.zip_postcode}}
                </address>
                <address v-else>
                  <span v-if="currentKOL.address.address_1">{{currentKOL.address.address_1}}<br></span>
                  {{currentKOL.address.city}}, {{currentKOL.address.state}} {{currentKOL.address.zip_postcode}}
                </address>
                <div v-if="currentKOL.email">
                <h5 style="font-size:16px;" class="pt-2">Email</h5>
                <p>{{currentKOL.email}}</p>
                </div>
                <div v-if="currentKOL.phone">
                  <h5 style="font-size:16px;" class="pt-2">Phone</h5>
                  <p>{{currentKOL.phone}}</p>
                </div>
                <div v-if="currentKOL.affiliation">
                  <h5 style="font-size:16px;" class="pt-2">Affiliation</h5>
                  <p>{{currentKOL.affiliation}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="info-card-footer-multiple" v-if="has_multiple_download_btns">
            <a :href="`${clientData.kol_pdf_url}${currentKOL.profile_pdf}`" target="_blank" class="btn btn-secondary btn-block" style="font-weight: 500;"><font-awesome-icon icon="cloud-download-alt"/> Download Profile PDF</a>
          </div>
          <div class="info-card-footer" v-else>
            <a :href="getURL(currentKOL)" target="_blank" class="btn btn-secondary btn-block" style="font-weight: 500;"><font-awesome-icon icon="cloud-download-alt"/> Download Profile PDF</a>
          </div>
          </div>
          </transition-group>
    </div>
    <div class="map-legend" v-if="hasLegend">
      <div class="d-flex justify-content-between align-items-center p-2">
        <div v-for="(item, index) in legendData" :key="index">
          <img :src="`https://assets.simpsonhealthcare.com/simpson/${item.icon}`" style="width: 24px;">
          &nbsp;<span style="font-size: 12px;"><strong>{{ item.name }}</strong></span>
        </div>

      </div>
    </div>
    <map-info-window v-if="currentMarkerClick" :lat="customMarker.lat" v-on:currentMarkerStatus="closeCustomMarker" :html="getContent(customMarker)" :height="infoWindowHeight" :lng="customMarker.lon" :customMarker="customMarker" ref="customMarkers"></map-info-window>
    <cluster-map-info-window v-if="clusterClick" :cluster="mapCluster" ref="cluster">
      <div v-for="i in [currentClusterIndex]" :key="i">
        <div class="d-flex justify-content-between" v-if="currentCluster !== undefined">
          <div @click="prevCluster" :style="{ 'margin-top': infoWindowHeight/2 + 'px' }" style="cursor:pointer; margin-left:-20px; width: 20px; height: 35px; background:#fff; z-index:4;">
            <font-awesome-icon icon="chevron-left" style="font-size: 18px; margin-top: 8px;"/>
          </div>
          <div class="info-box-position">
            <div class="info-box-wrap d-flex justify-content-between" style="width: 295px;">
              <div class="mySlides" style="padding: 5px;">
                <div class="info-box-text-wrap-sites" :style="{ height: infoWindowHeight + 'px' }">
                  <!-- <h4>{{currentCluster.hospital_name}}</h4>
                  <h6 class="address">{{currentCluster.city}}, {{currentCluster.state}} {{currentCluster.zip}}</h6> -->
                  <div v-html="getContent(currentCluster)"></div>
                </div> 
              </div>
              <div class="close-btn box-close" @click="closeCluster">
                <font-awesome-icon icon="times-circle" style="font-size: 20px;"/>
              </div>
            </div>
          </div>    
          <div @click="nextCluster" :style="{ 'margin-top': infoWindowHeight/2 + 'px' }" style="cursor:pointer; margin-right:-20px; width: 20px; height: 35px; background:#fff; z-index:4;">
            <font-awesome-icon icon="chevron-right" style="font-size: 18px; margin-top: 8px;"/>
          </div>
        </div>
      </div>
    </cluster-map-info-window>
  </div>
</template>

<script>
import MarkerClusterer from '@googlemaps/markerclustererplus'
import axios from 'axios'
import gmapsInit from '@/utils/gmaps'
import { forEach } from 'lodash'
import JQuery from 'jquery'
import MapInfoWindow from '@/components/MapInfoWindow.vue'
import ClusterMapInfoWindow from '@/components/ClusterMapInfoWindow.vue'
import HeatmapFilters from '@/components/HeatmapFilters.vue'
import CustomHeatmapFilters from '@/components/CustomHeatmapFilters.vue'
window.$ = JQuery
export default {
  name: 'Heatmap',
  components: {
    MapInfoWindow,
    ClusterMapInfoWindow,
    HeatmapFilters,
    CustomHeatmapFilters
  },
  data() {
    return {
      map: null,
      ibOptions: null,
      currentIb: '',
      kol: false,
      pi: false,
      kolMarkers: [],
      KOLMarkerClusterer: {},
      speakerMarkers: [],
      SpeakerMarkerClusterer: {},
      data: {},
      show: true,
      referralsTo: true,
      referralsFrom: false,
      referToMap: false,
      referFromMap: false,
      polygons: [],
      polygons2: [],
      showCard: false,
      kols: [],
      pi_centers: [],
      currentIndex: 0,
      allKOLs: [],
      showKOLFilters: false,
      hasCustomFilters: false,
      PIMarkerClusterer: {},
      mapCluster: null,
      clusterClick: false,
      currentClusterIndex: 0,
      clientData: {},
      kolData: {},
      heatmapSelection: {},
      searchKOL: {},
      customClusterColor: '#000',
      dynamicMarkerClusterer: [],
      markerClusterers: [],
      computedStyle: false,
      markerGroups: {},
      currentMarkerClick: false,
      customMarker: {},
      customLat: '',
      customLon: '',
      currentMarker: null,
      markerContent: '',
      infoWindowHeight: null,
      mapType: '',
      mapCenter: null,
      customData: null,
      has_multiple_download_btns: false,
      hasLegend: false,
      legendData: []
    }
  },
  computed: {
    currentKOL: function() {
      return this.kols[Math.abs(this.currentIndex) % this.kols.length];
    },
    currentCluster: function() {
      // console.log(this.pi_centers[0], this.pi_centers[Math.abs(this.currentClusterIndex) % this.pi_centers.length])
      return this.pi_centers[Math.abs(this.currentClusterIndex) % this.pi_centers.length];
      
    },
  },
  watch: {
    customMarker(newMarker, oldMarker) {
      const position = new google.maps.LatLng(newMarker.lat, newMarker.lon)
      if(this.$refs.customMarkers) {
        this.$refs.customMarkers.changePosition(position)
      }
    },
    mapCluster(newCluster, oldCluster) {
      if(this.$refs.cluster) {
        this.$refs.cluster.changePosition(newCluster.getCenter())
      }
    }
  },
  methods: {
    handle_function_call(function_name, item, event) {
    	this[function_name](item, event)
    },
    getImage: function (item) {
      if(item.hasOwnProperty('photo')) {
        return encodeURIComponent(item.photo.trim())
      }
      if(item.hasOwnProperty('image')) {
        return encodeURIComponent(item.image.trim())
      }
    },
    getData: function () {
       axios.get(`https://simpsonapi.com/v2/conventions/3x-data/${localStorage.getItem('project')}`).then(res => {
        // console.log(res)
        this.clientData = res.data.data
        this.has_multiple_download_btns = this.clientData.multiple_download_btns
        this.mapData = this.clientData.map_type
        this.setMapClusterStyles()
        //this.getKOLs()
       })
    },
    getKOLs: function () {
      // console.log(this.clientData.project_url)
      const indication = localStorage.getItem('access_type')
      let url = ''
      if(this.clientData.has_multiple_indications) {
        url = `${this.clientData.project_url}${this.clientData.db_name}/${indication}/kols`
      } else {
        url = `${this.clientData.project_url}${this.clientData.db_name}/kols`
      }
      axios.get(url).then(res => {
        //  console.log(res)
         this.kolData = res.data
      })
    },
    showData: function (event) {
      this.showKOLs(event)
    },
    filteredItems: function (data) {
      this.inputForm = data
      this.getFilteredData()
    },
    customFilteredItems: function (data) {
      this.getCustomFilteredData(data, this.customData)
    },
    searchName: function (data) {
      let item = data.search_kol
      if(item.length > 0 && item !== undefined) {
        this.lookupName(data.search_kol)
      } else if(item.length == 0)  {
        this.getFilteredData()
      }
    },
    getURL: function (item) {
      let url = ''
      if (this.clientData.has_multiple_indications) {
        url = `${this.clientData.kol_pdf_url}${item.molecule_indication}-pdf.php?id=${item.kol_id}`
      } else {
        url = `${this.clientData.kol_pdf_url}${item.kol_id}`
      }
      return url
    },
    showKOLs: function (item, event) {
      if (event.target.checked) {
        this.showKOLFilters = !this.showKOLFilters
        const indication = localStorage.getItem('access_type')
        let url = ''
        if(this.clientData.has_multiple_indications) {
          url = `${this.clientData.project_url}${this.clientData.db_name}/${indication}/kols`
        } else {
          url = `${this.clientData.project_url}${this.clientData.db_name}/kols`
        }
        axios.get(url).then(res => {
          let markers = []
          let datakols = res.data.kols
          // console.log(datakols)
          this.allKOLs = datakols
          for (let i=0; i < datakols.length; i++){
            let latlng = {}
            if(datakols[i].hasOwnProperty('address')) {
              latlng = new google.maps.LatLng(datakols[i].address.lat, datakols[i].address.lon)
            } else {
              latlng = new google.maps.LatLng(datakols[i].lat, datakols[i].lon)
            }
            let map = this.map
            let html = datakols[i]

            let marker = new CustomMarker(
              'customMarker',
              latlng,
              map,
              `${this.clientData.kol_image_url}${this.getImage(datakols[i])}`,
              null,
              html,
              null
            )
            this.markerClickHandler(marker, 'kols', '', '')
            markers.push(marker)
          }
          let markerCluster = new MarkerClusterer(this.map, markers, {
            maxzoom: 10,
            gridSize: 40,
            zoomOnClick: false,
            styles: [{
              textColor: '#fff',
              width: 30,
              height: 30,
              className: 'custom-clustericon-1'
            },
            {
              textColor: '#fff',
              width: 40,
              height: 40,
              className: 'custom-clustericon-1'
            },
            {
              textColor: '#fff',
              width: 50,
              height: 50,
              className: 'custom-clustericon-1'
            }],
            clusterClass: 'custom-clustericon'
          })
          

          this.KOLMarkerClusterer = markerCluster
          this.clusterClickHandler(this.KOLMarkerClusterer)
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        this.showKOLFilters = !this.showKOLFilters
        this.KOLMarkerClusterer.clearMarkers()
        this.showCard = false
      }
    },

    showKOLSpeakers: function (item, event) {
      if (event.target.checked) {
        const indication = localStorage.getItem('access_type')
        let url = ''
 
        url = `${this.clientData.project_url}${this.clientData.db_name}/speakers`
        
        axios.get(url).then(res => {
          let markers = []
          let datakols = res.data.kols
          // console.log(datakols)
          this.allKOLs = datakols
          for (let i=0; i < datakols.length; i++){
            let latlng = {}
            if(datakols[i].hasOwnProperty('address')) {
              latlng = new google.maps.LatLng(datakols[i].address.lat, datakols[i].address.lon)
            } else {
              latlng = new google.maps.LatLng(datakols[i].lat, datakols[i].lon)
            }
            let map = this.map
            let html = datakols[i]
            let color = ''

            if(datakols[i].speaker_status == 'Speaker') {
              color = 'rgb(240, 173, 78)'
            } else {
              color = 'rgb(217, 83, 79)'
            }

            let marker = new CustomMarker(
              'speakerMarker',
              latlng,
              map,
              `${this.clientData.kol_image_url}${this.getImage(datakols[i])}`,
              null,
              html,
              color
            )
            this.markerClickHandler(marker, 'kols', '', '')
            markers.push(marker)
          }

          let markerCluster = new MarkerClusterer(this.map, markers, {
            maxzoom: 10,
            gridSize: 40,
            zoomOnClick: false,
            styles: [{
              textColor: '#fff',
              width: 30,
              height: 30,
              className: 'custom-clustericon-3'
            },
            {
              textColor: '#fff',
              width: 40,
              height: 40,
              className: 'custom-clustericon-3'
            },
            {
              textColor: '#fff',
              width: 50,
              height: 50,
              className: 'custom-clustericon-3'
            }],
            clusterClass: 'custom-clustericon'
          })
          

          this.SpeakerMarkerClusterer = markerCluster
          this.clusterClickHandler(this.SpeakerMarkerClusterer)
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        this.SpeakerMarkerClusterer.clearMarkers()
        this.showCard = false
      }
    },


    showColorCodedMap: function (item, event) {
      let url = ''
      if (event.target.checked) {
        this.hasLegend = item.has_legend
        if(item.has_legend){
          this.legendData = item.legend
        }
        this.markerGroups[item.api_endpoint] = []
        // console.log('markerGroup', this.markerGroups[item.api_endpoint])
        url = `${this.clientData.project_url}${this.clientData.db_name}/${item.api_endpoint}`
        
        axios.get(url).then(res => {
          let data = item.type === 'kols' ? res.data.kols : res.data
          // console.log(data)
          for (let i=0; i < data.length; i++){
            let latlng = {}
            let map = this.map
            let html = data[i]
            let marker = {}
            if (item.type === 'kols') {
              latlng = new google.maps.LatLng(data[i].address.lat, data[i].address.lon)
              marker = new CustomMarker(
                latlng,
                map,
                `${this.clientData.kol_image_url}${this.getImage(data[i])}`,
                null,
                html
              )
              this.markerClickHandler(marker, 'kols', item, html)
            } else {
              latlng= new google.maps.LatLng(data[i].lat, data[i].lon)
              let map_icon = ''
              if(data[i].ped_adult == 'Adult & Pediatric'){
                map_icon = item.map_icon_1
              }
              if(data[i].ped_adult == 'Adult'){
                map_icon = item.map_icon_2
              }
              if(data[i].ped_adult == 'Pediatric'){
                map_icon = item.map_icon_3
              }
              marker = new google.maps.Marker({
                position: latlng,
                map: map,
                icon: `https://assets.simpsonhealthcare.com/simpson/${map_icon}`,
                title: data[i].id
              })
              this.markerClickHandler(marker, 'other', item, html)
            }

            this.markerGroups[item.api_endpoint].push(marker)
          }
          
          // this.markerGroups[item.api_endpoint] = new MarkerClusterer(this.map, this.markerGroups[item.api_endpoint], {
          //   maxzoom: 10,
          //   gridSize: 40,
          //   zoomOnClick: false,
          //   styles: [{
          //     textColor: '#fff',
          //     width: 30,
          //     height: 30,
          //     className: 'custom-clustericon-'+item.id
          //   },
          //   {
          //     textColor: '#fff',
          //     width: 40,
          //     height: 40,
          //     className: 'custom-clustericon-'+item.id
          //   },
          //   {
          //     textColor: '#fff',
          //     width: 50,
          //     height: 50,
          //     className: 'custom-clustericon-'+item.id
          //   }],
          //   clusterClass: 'custom-clustericon'
          // })
          // if(item.type === 'kols') {
          //   this.clusterClickHandler(this.markerGroups[item.api_endpoint], item)
          // } else {
          //   this.customClusterClickHandler(this.markerGroups[item.api_endpoint], item)
          // }
          
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        this.markerGroups[item.api_endpoint].clearMarkers()
        this.hasLegend = false
      }
    },


    getFilteredData: function () {
      let url = ''
      const indication = localStorage.getItem('access_type')
      if (this.clientData.has_multiple_indications) {
        url = `${this.clientData.project_url}${this.clientData.db_name}/${indication}/kols/filter`
      } else {
        url = `${this.clientData.project_url}${this.clientData.db_name}/kols/filter`
      }
      axios.post(url, JSON.stringify(this.inputForm)).then(res => {
        //  console.log(res.data)
         this.KOLMarkerClusterer.clearMarkers()
         this.kolData = res.data
         let markers = []
        let datakols = res.data.kols
        // console.log(datakols)
        this.allKOLs = datakols
        for (let i=0; i < datakols.length; i++){
          let latlng = {}
          if(datakols[i].hasOwnProperty('address')) {
            latlng = new google.maps.LatLng(datakols[i].address.lat, datakols[i].address.lon)
          } else {
            latlng = new google.maps.LatLng(datakols[i].lat, datakols[i].lon)
          }
          let map = this.map
          let html = datakols[i]

          let marker = new CustomMarker(
              'customMarker',
              latlng,
              map,
              `${this.clientData.kol_image_url}${this.getImage(datakols[i])}`,
              null,
              html,
              null
            )
          this.markerClickHandler(marker, 'kols')
          markers.push(marker)
        }

        let markerCluster = new MarkerClusterer(this.map, markers, {
          maxzoom: 10,
          gridSize: 40,
          zoomOnClick: false,
          styles: [{
            textColor: '#fff',
            width: 30,
            height: 30,
            className: 'custom-clustericon-1'
          },
          {
            textColor: '#fff',
            width: 40,
            height: 40,
            className: 'custom-clustericon-1'
          },
          {
            textColor: '#fff',
            width: 50,
            height: 50,
            className: 'custom-clustericon-1'
          }],
          clusterClass: 'custom-clustericon'
        })
        

        this.KOLMarkerClusterer = markerCluster
        this.clusterClickHandler(this.KOLMarkerClusterer)
      })
      .catch(error => {
        console.log(error)
      })
    },
    lookupName: function (name) {
      let url = ''
      const indication = localStorage.getItem('access_type')
      if (this.clientData.has_multiple_indications) {
        url = `${this.clientData.project_url}${this.clientData.db_name}/${indication}/searchkol/${name}`
      } else {
        url = `${this.clientData.project_url}${this.clientData.db_name}/searchkol/${name}`
      }
      axios.get(url).then(res => {
        //  console.log(res.data)
         this.KOLMarkerClusterer.clearMarkers()
         this.kolData = res.data
         let markers = []
        let datakols = res.data.kols
        // console.log(datakols)
        this.allKOLs = datakols
        for (let i=0; i < datakols.length; i++){
          let latlng = {}
          if(datakols[i].hasOwnProperty('address')) {
            latlng = new google.maps.LatLng(datakols[i].address.lat, datakols[i].address.lon)
          } else {
            latlng = new google.maps.LatLng(datakols[i].lat, datakols[i].lon)
          }
          let map = this.map
          let html = datakols[i]

          let marker = new CustomMarker(
              'customMarker',
              latlng,
              map,
              `${this.clientData.kol_image_url}${this.getImage(datakols[i])}`,
              null,
              html,
              null
            )
          this.markerClickHandler(marker, 'kols')
          markers.push(marker)
        }

        let markerCluster = new MarkerClusterer(this.map, markers, {
          maxzoom: 10,
          gridSize: 40,
          zoomOnClick: false,
          styles: [{
            textColor: '#fff',
            width: 30,
            height: 30,
            className: 'custom-clustericon-1'
          },
          {
            textColor: '#fff',
            width: 40,
            height: 40,
            className: 'custom-clustericon-1'
          },
          {
            textColor: '#fff',
            width: 50,
            height: 50,
            className: 'custom-clustericon-1'
          }],
          clusterClass: 'custom-clustericon'
        })
        this.KOLMarkerClusterer = markerCluster
        this.clusterClickHandler(this.KOLMarkerClusterer)
      })
      .catch(error => {
        console.log(error)
      })
    },
    next: function() {
      if(this.currentIndex !== this.kols.length - 1) {
        this.currentIndex += 1;
      }
    },
    prev: function() {
      if(this.currentIndex !== 0) {
        this.currentIndex -= 1;
      }
    },

    nextCluster: function() {
      if(this.currentClusterIndex !== this.pi_centers.length - 1) {
        this.currentClusterIndex += 1;
      }
    },
    prevCluster: function() {
      if(this.currentClusterIndex !== 0) {
        this.currentClusterIndex -= 1;
      }
    },

    showPICenters: function (event) {
      if (event.target.checked) {
        axios.get(`${this.clientData.project_url}${this.clientData.db_name}/centers/pi`).then(res => {
          let markers = []
          let sites = res.data
          // console.log(sites)
          for (let i=0; i < sites.length; i++){
            let latlng = new google.maps.LatLng(sites[i].lat, sites[i].lon)
            let map = this.map
            let html = sites[i]
            var image = 'assets/hospital-building-blue.png'
            let marker = new google.maps.Marker({
              position: latlng,
              map: map,
              icon: image,
              title: sites[i].id
            })
            
            this.markerClickHandler(marker)
            markers.push(marker)
          }

         
          let markerCluster = new MarkerClusterer(this.map, markers, {
            maxzoom: 10,
            gridSize: 40,
            zoomOnClick: false,
            styles: [{
              textColor: '#fff',
              width: 30,
              height: 30,
              className: 'custom-clustericon-2'
            },
            {
              textColor: '#fff',
              width: 40,
              height: 40,
              className: 'custom-clustericon-2'
            },
            {
              textColor: '#fff',
              width: 50,
              height: 50,
              className: 'custom-clustericon-2'
            }],
            clusterClass: 'custom-clustericon'
          })

          this.PIMarkerClusterer = markerCluster
          this.PIClusterClickHandler(this.PIMarkerClusterer)
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        this.PIMarkerClusterer.clearMarkers()
        this.closeCluster()
      }
      
    },
    
    showMapLayers: function (item, event) {
      let url = ''
      if (event.target.checked) {
        // console.log(item)
        if('filters' in item) {
          this.hasCustomFilters = !this.hasCustomFilters
          this.customData = item
        }
        this.markerGroups[item.api_endpoint] = []
        // console.log('markerGroup', this.markerGroups[item.api_endpoint])
        const indication = localStorage.getItem('access_type')
        if (item.has_multiple_indications) {
          url = `${this.clientData.project_url}${this.clientData.db_name}/${indication}/${item.api_endpoint}`
        } else {
          url = `${this.clientData.project_url}${this.clientData.db_name}/${item.api_endpoint}`
        }
        axios.get(url).then(res => {
          let data = item.type === 'kols' ? res.data.kols : res.data
          // console.log(data)
          for (let i=0; i < data.length; i++){
            let latlng = {}
            let map = this.map
            let html = data[i]
            let marker = {}
            if (item.type === 'kols') {
              latlng = new google.maps.LatLng(data[i].address.lat, data[i].address.lon)
              marker = new CustomMarker(
                latlng,
                map,
                `${this.clientData.kol_image_url}${this.getImage(data[i])}`,
                null,
                html
              )
              this.markerClickHandler(marker, 'kols', item, html)
            } else {
              latlng= new google.maps.LatLng(data[i].lat, data[i].lon)
              marker = new google.maps.Marker({
                position: latlng,
                map: map,
                icon: `https://assets.simpsonhealthcare.com/simpson/${item.map_icon}`,
                title: data[i].id
              })
              this.markerClickHandler(marker, 'other', item, html)
            }

            this.markerGroups[item.api_endpoint].push(marker)
          }
          
          this.markerGroups[item.api_endpoint] = new MarkerClusterer(this.map, this.markerGroups[item.api_endpoint], {
            maxzoom: 10,
            gridSize: 40,
            zoomOnClick: false,
            styles: [{
              textColor: '#fff',
              width: 30,
              height: 30,
              className: 'custom-clustericon-'+item.id
            },
            {
              textColor: '#fff',
              width: 40,
              height: 40,
              className: 'custom-clustericon-'+item.id
            },
            {
              textColor: '#fff',
              width: 50,
              height: 50,
              className: 'custom-clustericon-'+item.id
            }],
            clusterClass: 'custom-clustericon'
          })
          if(item.type === 'kols') {
            this.clusterClickHandler(this.markerGroups[item.api_endpoint], item)
          } else {
            this.customClusterClickHandler(this.markerGroups[item.api_endpoint], item)
          }
          
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        if('filters' in item) {
          this.hasCustomFilters = !this.hasCustomFilters
        }
        this.markerGroups[item.api_endpoint].clearMarkers()
      }
    },
    getCustomFilteredData: function (data, item) {
      const indication = localStorage.getItem('access_type')
      let url = ''
      if (item.has_multiple_indications) {
        url = `${this.clientData.project_url}${this.clientData.db_name}/${indication}/${item.api_endpoint}/filter`
      } else {
        url = `${this.clientData.project_url}${this.clientData.db_name}/${item.api_endpoint}/filter`
      }
    axios.post(url, JSON.stringify(data)).then(res => {
        let data = item.type === 'kols' ? res.data.kols : res.data.data
        console.log(data)
        this.markerGroups[item.api_endpoint].clearMarkers()
        this.markerGroups[item.api_endpoint] = []
        // console.log(data)
        for (let i=0; i < data.length; i++){
          let latlng = {}
          let map = this.map
          let html = data[i]
          let marker = {}
          if (item.type === 'kols') {
            latlng = new google.maps.LatLng(data[i].address.lat, data[i].address.lon)
            marker = new CustomMarker(
              latlng,
              map,
              `${this.clientData.kol_image_url}${this.getImage(data[i])}`,
              null,
              html
            )
            this.markerClickHandler(marker, 'kols', item, html)
          } else {
            latlng= new google.maps.LatLng(data[i].lat, data[i].lon)
            marker = new google.maps.Marker({
              position: latlng,
              map: map,
              icon: `https://assets.simpsonhealthcare.com/simpson/${item.map_icon}`,
              title: data[i].id
            })
            this.markerClickHandler(marker, 'other', item, html)
          }
          this.markerGroups[item.api_endpoint].push(marker)
        }
        
        this.markerGroups[item.api_endpoint] = new MarkerClusterer(this.map, this.markerGroups[item.api_endpoint], {
          maxzoom: 10,
          gridSize: 40,
          zoomOnClick: false,
          styles: [{
            textColor: '#fff',
            width: 30,
            height: 30,
            className: 'custom-clustericon-'+item.id
          },
          {
            textColor: '#fff',
            width: 40,
            height: 40,
            className: 'custom-clustericon-'+item.id
          },
          {
            textColor: '#fff',
            width: 50,
            height: 50,
            className: 'custom-clustericon-'+item.id
          }],
          clusterClass: 'custom-clustericon'
        })
        if(item.type === 'kols') {
          this.clusterClickHandler(this.markerGroups[item.api_endpoint], item)
        } else {
          this.customClusterClickHandler(this.markerGroups[item.api_endpoint], item)
          }
      })
      .catch(error => {

       console.log(error)
      })
    },
    getContent: function (data) {
      console.log('data', data)
      console.log('marker content', this.markerContent)
      // return this.markerContent
      let content = ''
      let cclass = ''
      let items = ''
      forEach(this.markerContent, (item) => {
        cclass = item.class ? `class="${item.class}"` : ''
        if(Array.isArray(item.model) && item.class == 'address') {
          forEach(item.model, (v, k) => {
            console.log(v)
            items += data[v] 
            if(k !== item.model.length - 1) {
              if(v == "state" || v == 'zip' || v == 'country') {
                 items += " "  
              } else {
                items += ", "
              }
              
            }
          })
        } else if (Array.isArray(item.model)) {
          forEach(item.model, (v, k) => {
            items += data[v]
            if(k !== item.model.length - 1) {
              items += ` ${item.separator} `
            }
          })
        } else {
          items = data[item.model] ? data[item.model] : ''
        }

        if(item.style !== null) {
          content += `<h${item.heading_type} ${cclass} style="${item.style}">${item.text}${items}</h${item.heading_type}>`
        } else if(item.heading_type === 'a' && data[item.model] !== '') {
          let ta = data['therapeutic_area']
          let pdfType = ''
          if(ta == 'Psychiatry') {
            pdfType = '114'
          } else if (ta == 'Movement Disorders') {
            pdfType = '944'
          } else {
            pdfType = '562'
          }
          content += `<a href="https://employee.simpsonhealthcare.com/praxis/5105/prax${pdfType}-pdf.php?id=${data[item.model]}" target="_blank">Profile PDF</a>`
        } else {
          content += `<h${item.heading_type} ${cclass}>${item.text}${item.model === 'pi_email' ? items.toLowerCase() : items }</h${item.heading_type}>`
        }
        items = ''
        console.log(content)
      })

   

      return content
    },
    markerClickHandler: function (marker, type, item, html) {
      google.maps.event.addListener(marker, 'click', () => {
        // console.log(marker, type, item, html)
        if(type === 'kols') {
          const data = marker.data
          this.kols = []
          let address_2 = ''
          this.kols.push(data)
          // console.log(this.kols)
          this.showCard = true
          // console.log(this.showCard)
        }
        if(type === 'other') {
          this.customMarker = html
          this.markerContent = item.cluster_template
          this.infoWindowHeight = item.info_window_height
          // console.log(this.customMarker)
          this.currentMarkerClick = true          
        }
      })
    },
    clusterClickHandler: function (markerCluster) {
      google.maps.event.addListener(markerCluster, 'clusterclick', (cluster) => {
        var markers = cluster.getMarkers()
        this.kols = []
        forEach(markers, (item) => {
          this.kols.push(item.data)
        })
        this.currentIndex = 0
        this.showCard = true
        // console.log(this.kols)
      })
    },
    customClusterClickHandler: function (markerCluster, data) {
      google.maps.event.addListener(markerCluster, 'clusterclick', (cluster) => {      
        var markers = cluster.getMarkers()
        this.mapCluster = cluster
        this.pi_centers = []
        forEach(markers, (item) => {
          axios.get(`${this.clientData.project_url}${this.clientData.db_name}/${data.api_endpoint}/${item.title}`).then(res => {
            this.pi_centers.push(res.data)
          })
        })
        this.markerContent = data.cluster_template
        this.infoWindowHeight = data.info_window_height
        this.currentClusterIndex = 0  
        this.clusterClick = true
      })
    },
    closeCluster: function () {
      this.$refs.cluster.closeWindow()
      this.clusterClick = false
    },
    closeCustomMarker: function () {  
      this.currentMarkerClick = false
    },
    getMap(callback) {
      let vm = this
      function checkForMap() {
        if (vm.map) callback(vm.map)
        else setTimeout(checkForMap, 200)
      }
      checkForMap()
    },
    setMapClusterStyles: function () {
      var css = document.createElement('style')
      css.setAttribute("type", "text/css")

      let styles = '';
      forEach(this.clientData.heatmap_layers, (item) => {
        styles += `.custom-clustericon-${item.id} { 
                  --cluster-color: ${item.map_cluster_color};
                  }`;
      })
      css.appendChild(document.createTextNode(styles));
      document.getElementsByTagName("head")[0].appendChild(css)
    }
  },
  async mounted () {
    try {
      this.getData()
      const google = await gmapsInit()
      const geocoder = new google.maps.Geocoder()

      if(this.clientData.map_type === 'global') {
        this.mapCenter = { lat: 41.50649244523482, lng: -50.50950015195509 }
      } else {
        this.mapCenter = { lat: 39.43404279037979, lng: -97.31125796445514 }
      }
      const mapConfig = {
            center: { lat: 39.43404279037979, lng: -97.31125796445514 },
            zoom: 5,
            panControl: false,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            scaleControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
            },
            styles: [{
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        { "color": "#444444" }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        { "color": "#f2f2f2" }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        { "saturation": -100 },
                        { "lightness": 45 }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        { "visibility": "simplified" }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        { "visibility": "off" }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        { "color": "#46bcec" },
                        { "visibility": "on" }
                    ]
                }
            ]}
      this.map = new google.maps.Map(document.getElementById('map'), mapConfig)
      
      const customMarker = document.createElement('script')
      customMarker.setAttribute('src', 'https://assets.simpsonhealthcare.com/simpson/custommarker-test.js')
      document.head.appendChild(customMarker)

      const infoBox = document.createElement('script')
      infoBox.setAttribute('src', 'https://assets.simpsonhealthcare.com/simpson/infobox.js')
      document.head.appendChild(infoBox) 

      // const mc = document.createElement('script')
      // mc.setAttribute('src', 'https://assets.simpsonhealthcare.com/simpson/markerclusterer.js')
      // document.head.appendChild(mc) 

    } catch (error) {
      console.error(error)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style text="css">

.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width:100%;
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.25s;
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

#map {
  top: 77px;
  width: 100%;
  bottom: 0;
  height: calc(100vh - 77px);
  z-index: 1;
}

.customMarker {
  position:absolute;
  cursor:pointer;
  background:#fff;
  width:50px;
  height:50px;
  /* -width/2 */
  margin-left:-25px;
  /* -height + arrow */
  margin-top:-58px;
  border-radius:50%;
  padding:0px;
  box-shadow: 5px 5px 5px #888;
}
.customMarker:after {
  content:"";
  position: absolute;
  bottom: -10px;
  left: 10px;
  border-width: 15px 15px 0;
  border-style: solid;
  border-color: #fff transparent;
  display: block;
  width: 0;
}
.customMarker img {
  width:40px;
  height:40px;
  object-fit: cover;
  margin:5px;
  border-radius:50%;
}

.customMarker label {
  color: #000;
  width: 100px;
  background-color: #fff;
  padding: 2px;
  border-radius: 5px;
  z-index: 1;
  position: relative;
  margin-left: -20px;
  margin-top: 8px;
  box-shadow: 2px 2px 2px #ccc;
}

.speakerMarker {
  position:absolute;
  cursor:pointer;
  background:#fff;
  width:50px;
  height:50px;
  margin-left:-25px;
  margin-top:-58px;
  border-radius:50%;
  padding:0;
  -webkit-box-shadow:5px 5px 5px #888;
  box-shadow:5px 5px 5px #888;
}

.speakerMarker img {
  width:40px;
  height:40px;
  -o-object-fit:cover;
  object-fit:cover;
  margin:5px;
  border-radius:50%;
}
.speakerMarker label{
  color:#000;
  width:100px;
  background-color:#fff;
  padding:2px;
  border-radius:5px;
  z-index:1;
  position:relative;
  margin-left:-20px;
  margin-top:8px;
  -webkit-box-shadow:2px 2px 2px #ccc;
  box-shadow:2px 2px 2px #ccc;
}


.expandpanel{
  position: fixed;
  top: 95px;
  height: 50px;
  width: 25px;
  background-color: #fff;
  color: #ddd;
  padding: 12px 0px 0px 0px;
  box-shadow: 1px 1px 1px #888;
  z-index: 1000;
}

/** Infobox **/
.info-box-position {
  margin-top: 8px;
}

.info-box-wrap {
  background:#fff;
  border-radius: 4px;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
}

.info-box-assets {
  position:fixed;
  height: 100%;
}

.info-box-wrap  img {
  width:95px;
  margin-right: 10px;
  padding-top:0;
  margin-top:-20px;
}

.close-btn {
  position: relative;
  cursor: pointer !important;
  margin-top: 4px;
  margin-right: 4px;
  z-index: 1005;
}

.info-box-footer {
  position: fixed;
  height: 40px;
  width: 100%;
  background-color: #f8f9fa;
  border-top: 1px solid #ccc;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.15);
}


.info-box-text-wrap {
  position:relative;
  left: 100px;
  text-align: left;
  height:111px !important;
  margin-top: -16px;
  padding-bottom: 5px;
}
    
.info-box-text-wrap-sites {
  position:relative;
  left: 5px;
  text-align: left;
  height:95px;
}

.info-box-text-wrap h4, .info-box-text-wrap h5,
.info-box-text-wrap-sites h4, .info-box-text-wrap-sites h5 {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: bold;
}

.info-box-text-wrap h5,
.info-box-text-wrap-sites h5 {
  margin-top: 0px;
  font-size: 13px !important;
  color: #aaa;
}

.info-box-text-wrap h6,
.info-box-text-wrap-sites h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px !important;
}
    
.info-box-text-wrap h6.address,
.info-box-text-wrap-sites h6.address {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.info-box-text-wrap h6.tier,
.info-box-text-wrap-sites h6.tier {
  padding:2px 5px 1px 0;
  margin:0 0 0 0;
}

.infoBox:before{
  content : " ";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position:absolute;
  top:0px;
  left:130px;
}


 /* Custom cluster  */
.custom-clustericon {
  background: var(--cluster-color);
  color: #fff;
  border-radius: 100%;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.custom-clustericon::before,
.custom-clustericon::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: var(--cluster-color);
  opacity: 0.2;
  border-radius: 100%;
}

.custom-clustericon::before {
  padding: 7px;
}

.custom-clustericon::after {
  padding: 14px;
}

</style>
<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}


.map-menu {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 280px;
  list-style-type: none;
  margin: 0;
  padding: 77px 0 0 0;
  background: #fff;
  z-index: 1000;
}

.map-menu li {
  display: inline-block;
  color: #999;
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.map-menu .menu-header{
  width: 100%;
  height: 3em;
  line-height: 3em;
}

.map-legend {
  position: fixed;
  right: 35%;
  bottom: 10px;
  height: 50px;
  width: 400px;
  list-style-type: none;
  margin: 0;
  padding: 0px;
  background: #fff;
  z-index: 1000;
  border-radius: 10px;
  opacity: 0.95;
}

.info-card {
  position: fixed;
  overflow-y: auto;
  top: 85px;
  right: 10px;
  bottom: 125px;
  height: calc(100% - 200px);
  width: 300px;
  list-style-type: none;
  margin: 0;
  padding: 0px;
  background: #fff;
  z-index: 1000;
  border-radius: 10px;
  opacity: 0.95;
}

.info-card-footer {
  background: #fff;
  padding: 5px;
  position:fixed;
  bottom: 115px;
  width: 300px;
  right: 10px;
  height: 50px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.3);
}


.info-card-footer-multiple {
  background: #fff;
  padding: 5px;
  position:fixed;
  bottom: 115px;
  width: 300px;
  right: 10px;
  height: 50px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* .profile-image {
  width:100px;
  height:100px;
  margin:5px;
  border-radius:100px;
  
} */

.profile-image {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-size: cover;
  display: inline-block;
}

.list-profile-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: cover;
  display: inline-block;
}

/* Rounded sliders */

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #009370;
}

input:focus + .slider {
  box-shadow: 0 0 1px #009370;
}

input:checked + .slider.red {
  background-color: #ff6969;
}

input:focus + .slider.red {
  box-shadow: 0 0 1px #ff6969;
}

input:checked + .slider.blue {
  background-color: #0593f9;
}

input:focus + .slider.blue {
  box-shadow: 0 0 1px #0593f9;
}

input:checked + .slider.orange {
  background-color: #ff7e00;
}

input:focus + .slider.orange {
  box-shadow: 0 0 1px #ff7e00;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-padding {
  margin-top: 15px;
}

/** Icons */
.ee-icon {
  background-image: url('https://imgs.simpsonhealthcare.com/3x/external-experts.png');
  background-repeat: no-repeat;
  background-size: 38px 38px;
  background-position: 15px 9px;
  padding-left: 5px;
  width: 170px;
  text-transform: uppercase;
  height: 56px;
  line-height: 18px;
  padding-top: 18px;
}

.ee-icon-other {
  background-image: url('https://imgs.simpsonhealthcare.com/3x/external-experts.png');
  background-repeat: no-repeat;
  background-size: 38px 38px;
  background-position: 15px 9px;
  padding-left: 45px;
  width: 170px;
  text-transform: uppercase;
  height: 56px;
  line-height: 18px;
  padding-top: 18px;
}

.az-icon {
  text-align:left;
  padding-top: 10px;
  padding-left: 60px;
  width: 200px;
}

.site-icon {
  background-image: url('https://imgs.simpsonhealthcare.com/3x/sites.png');
  background-repeat: no-repeat;
  background-size: 38px 38px;
  background-position: 15px 9px;
  padding-left: 45px;
  width: 180px;
  text-transform: uppercase;
  height: 56px;
  line-height: 18px;
  padding-top: 18px;
}

.nav-tabs .nav-link {
  border-bottom: 1px solid transparent;
  color: #495057;
}
.nav-tabs .nav-link:hover {
  border: 1px solid transparent;
}
.nav-tabs .nav-link.active {
  border: 1px solid transparent;
  border-bottom: 3px solid #d9534f;
}


/* iOS segemented control */
.flight-types {
  display: flex;
  max-width: 250px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 12px;
  border: 1px solid #0275d8;
  border-radius: 3px;
  color: #0275d8;
}
.flight-types > input {
  display: none;
}
.flight-types > input:checked + label {
  color: #fff;
}
.flight-types > input:nth-of-type(1):checked ~ label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
}
.flight-types > input:nth-of-type(2):checked ~ label:last-of-type:before {
  transform: translateX(calc(100% + 0px));
}
.flight-types > input:nth-of-type(3):checked ~ label:last-of-type:before {
  transform: translateX(calc(200% + 0px));
}
.flight-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-bottom:0px;
  font-size: 13px;
}
.flight-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(50% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
}
.flight-types label {
  padding: 5px 3px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:before {
  background:#0275d8;
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:not(:last-child) {
  border-right: 1px solid #0275d8;
}

.list-group-item {
  padding: 10px;
}

</style>