import axios from 'axios'

const http2 = axios.create({
    baseURL: 'https://simpsonapi.com/',
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        responseType: "json",
    },
})

http2.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    console.log('token', token)
    if (!token) {
      return config;
    }
  
    config.headers['x-access-token'] = `${token}`;
  
    return config;
})

export default http2