import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import 'bootstrap'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarAlt, faDollarSign, faCompass, faUtensils, faFilter, faUserClock, faInfoCircle, faEnvelope, faPhoneAlt, faSortAlphaDown, faEllipsisH, faEllipsisV, faArrowLeft, faSortAlphaDownAlt,faMapMarkerAlt, faMapMarkedAlt, faChevronRight, faChevronLeft, faFilePdf, faChevronDown, faExclamationTriangle, faAngleDoubleLeft, faAngleDoubleRight, faCheckCircle, faChevronUp, faCaretSquareDown, faPaperclip, faEdit, faTrash, faStar, faPlus, faPlusCircle, faArchive, faSearch, faCloudDownloadAlt, faCheck, faTimes, faTimesCircle, faComments, faCommentAlt, faClock, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import Multiselect from 'vue-multiselect'
import VueFriendlyIframe from 'vue-friendly-iframe'
import VueTimeago from 'vue-timeago'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

library.add(faCalendarAlt, faDollarSign, faCompass, faUtensils, faFilter, faEnvelope, faPhoneAlt, faUserClock, faEllipsisH, faEllipsisV, faInfoCircle, faSortAlphaDown, faArrowLeft, faSortAlphaDownAlt, faMapMarkerAlt, faMapMarkedAlt, faChevronRight, faChevronLeft, faFilePdf, faCloudDownloadAlt, faChevronDown, faAngleDoubleLeft, faAngleDoubleRight, faCheckCircle, faChevronUp, faCaretSquareDown, faEdit, faTrash,
  faStar, faPlus, faPlusCircle, faArchive, faSearch, faCheck, faTimes, faTimesCircle, faCheckCircle, faExclamationTriangle, faComments, faPaperclip, faClock, faCommentAlt, faLongArrowAltLeft)

Vue.component('multiselect', Multiselect)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('VueSlider', VueSlider)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueFriendlyIframe)
Vue.use(VueVirtualScroller)
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
