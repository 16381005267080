import jwtDecode from 'jwt-decode'
import { Base64 } from 'js-base64'
import router from '@/router'
import { http } from '@/config/base-url'

export default class AuthService {

  login (user) {
    const userToken = Base64.encode(`${user.username}:${user.password}`)
    const rememberMe = {
      username: user.username,
      password: user.password,
      rememberme: user.rememberme
    }
    const encoded = Base64.encode(JSON.stringify(rememberMe))

    return http.get('v2/users/connex-login', {
      headers: {
        Authorization: `Basic ${userToken}`
      }
    }).then((res) => {
      const userData = jwtDecode(res.data.token)
      const userObject = {
        publicId: userData.public_id,
        email: userData.email,
        name: userData.name,
        roles: userData.roles,
        project_id: userData.project_id,
        access_type: userData.access_type,
        connex_modules: JSON.parse(userData.connex_modules),
        first_time: userData.first_time,
        firstName: userData.first_name,
        lastName: userData.last_name,
        crossBrands: userData.cross_brands,
        hasCrossBrandAccess: userData.has_cross_brand_access,
        type: userData.type
      }
      
      if (res.data && res.data.token) {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('user', JSON.stringify(userObject))
        user.rememberme ? localStorage.setItem('rememberMe', encoded) : localStorage.removeItem('rememberMe')
      }
      return userData
    }).catch((error) => {
      return error.response
    })
  }

  getProjectSettings (id) {
    return http.get(`v2/users/connex-settings/${id}`).then((res) => {
      return res.data
    })
  }

  async forgotPassword (user) {
    try {
      const res = await http.post('v2/users/connex/password/reset', user)
      const data = res
      return data
    } catch (error) {
      return error.response
    }
  }

  changePassword (data) {
    return http.post('v2/users/change-connex-password', data).then((res) => {
      return res.data
    })
  }
}
