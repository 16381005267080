<template>
  <div>
    <div class="iframe-wrapper">
      <div class="iframe-loading" v-if="iframeLoading">
        Page Loading...
      </div>
      <vue-friendly-iframe :src="externalSrc" @load="onLoad" @iframe-load="onIframeLoad" :style="{ 'display' : iframeLoading ? 'none' : 'block' }" frameborder="0"></vue-friendly-iframe>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Dashboard',
  components: {
  },
  data() {
    return {
      clientData: {},
      externalSrc: '',
      iframeLoading: true
    }
  },
  computed: {
  },
  methods: {
    getData: function () {
      axios.get(`https://simpsonapi.com/v2/conventions/3x-data/${localStorage.getItem('project')}`).then(res => {
        console.log(res)
        this.clientData = res.data.data
        this.externalSrc = this.clientData.nav_items[0].external_url
      })
    },
    onLoad: function() {
      console.log('iframe loaded');
      setTimeout(() => this.iframeLoading = false, 500);
      
    },
    onIframeLoad() {
      console.log('iframe loaded');
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style text="css">
iframe {
  height: 100%;
  width: 100%;
}
</style>
<style scoped>
.iframe-loading {
  display: flex;
  align-items: center;
  justify-content: center; 
  width: 10%; 
  text-align: center; 
  margin: 25% auto; 
  min-height: 100px; 
  background-color: rgba(33, 33, 33, .3); 
  align-items: center;
}
.iframe-wrapper {
  border: 1px solid gray;
  height: 100vh;
}
.vue-friendly-iframe {
  height: 100%;
  width: 100%;
}
</style>