<template>
  <div class="login__settings">
    <div class="login">
      <div class="login__header">
        <img class="header__logo" src="@/assets/3connex-logo.svg">
        <span class="header__text" :class="{'header__text__error': hasError, 'header__text__success': hasSuccess}">{{headerText}}</span>
      </div>
      <div class="login__main">
        <p style="padding: 10px 15px 0px 15px; margin:0px; text-align:left;">
          Please change your password before signing in. New password must follow the criteria below.
        </p>
        <ul style="text-align:left; margin-bottom: 0px;">
          <li v-bind:class="{ is_valid: containsEightCharacters }">At least 8 Characters</li>
          <li v-bind:class="{ is_valid: containsNumber }">Contains Number</li>
          <li v-bind:class="{ is_valid: containsUppercase }">Contains Uppercase</li>
          <li v-bind:class="{ is_valid: containsSpecialCharacter }">Contains Special Character</li>
        </ul>
        <form @submit.prevent="checkForm" novalidate="true">
          <div class="login__main__form">
            <div class="form__group">
              <label class="form__label" for="email">New Password</label>
              <input type="password" class="form__control" placeholder="New Password" @input="checkPassword" v-model="user.newpassword">
            </div>

            <div class="form__group">
              <label class="form__label" for="password">Verify Password</label>
              <input type="password" class="form__control" placeholder="Verify New Password" v-model="user.verifypassword">
            </div>
          </div>
          <div class="login__button">
            <input type="submit" value="Submit" class="signin-btn" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'

export default {
  name: 'change-password',
  data() {
    return {
      user: { newpassword: '', verifypassword: '' },
      headerText: 'Change Password',
      hasError: false,
      hasSuccess: false,
      passwordLength: 0,
      containsEightCharacters: false,
      containsNumber: false,
      containsUppercase: false,
      containsSpecialCharacter: false,
      validPassword: false
    }
  },
  methods: {
    checkPassword: function () {
      this.passwordLength = this.user.newpassword.length
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/

      if (this.passwordLength >= 8) {
        this.containsEightCharacters = true
      } else {
        this.containsEightCharacters = false
      }

      this.containsNumber = /\d/.test(this.user.newpassword)
      this.containsUppercase = /[A-Z]/.test(this.user.newpassword)
      this.containsSpecialCharacter = format.test(this.user.newpassword)

      if (this.containsEightCharacters === true &&
      this.containsSpecialCharacter === true &&
      this.containsUppercase === true &&
      this.containsNumber === true) {
        this.validPassword = true
      } else {
        this.validPassword = false
      }
    },
    checkForm: function (e) {
      const auth = new AuthService()

      if (!this.validPassword) {
        this.headerText = 'New password does not match criteria'
        this.hasError = true
        this.hasSuccess = false
      } else if (this.user.newpassword !== this.user.verifypassword) {
        this.headerText = 'Passwords do not match'
        this.hasError = true
        this.hasSuccess = false
      } else {
        console.log('password verified')
        this.headerText = 'Password changed'
        this.hasError = false
        this.hasSuccess = true
        const changeData = {
          newpassword: this.user.newpassword,
          email: JSON.parse(localStorage.getItem('user')).email,
          id: JSON.parse(localStorage.getItem('user')).publicId
        }
        auth.changePassword(changeData).then((data) => {
          auth.getProjectSettings(JSON.parse(localStorage.getItem('user')).project_id).then((res) => {
            console.log(res)
            localStorage.setItem('settings', JSON.stringify(res))
            localStorage.setItem('project', JSON.parse(localStorage.getItem('user')).project_id)
            localStorage.setItem('name', JSON.parse(localStorage.getItem('user')).name)
            const modules = JSON.parse(localStorage.getItem('user')).connex_modules
            console.log(modules[0].toLowerCase())
            this.$router.push(`/${modules[0].toLowerCase()}`)
          })
        })
      }
      e.preventDefault()
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  },
  mounted () {
    if (this.rememberMe) {
      if (this.rememberMe.rememberme) {
        this.user.username = this.rememberMe.username
        this.user.password = this.rememberMe.password
        this.user.rememberme = this.rememberMe.rememberme
      }
    }
  }
}

</script>


<style scoped>
.login__settings {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EDF1F3;
}

.login {
  width: 320px;
  height: 462px;
  background-color: #fff;
  border-radius: 4px;
}

.login__header {
  height: 50px;
  width: 100%;
  background-color: rgb(248, 250, 252);
  border-bottom: 1px solid rgb(248, 250, 252);
  display: flex;
  flex-direction: column;
}

.header__logo {
  height: 120px;
  margin-top: -120px;
}

.header__text {
  margin: 8px 0;
  font-size: 18px;
}

.header__text__error {
  color: #d9534f;
}

.header__text__success {
  color: #5cb85c;
}

.login__main {
    width: 100%;
    height: calc(372px - 50px);
}

.login__forgot {
  font-size: 12px;
  padding: 10px 0px;
}

.login__forgot a {
  text-decoration: none;
  color: #000;
}

.login__main__form {
    padding: 15px;
    text-align: left;
}

.form__group {
    padding: 10px 0px 0px 0px;
}

.form__label {
    display: inline-block;
    font-size: 12px;
    color: #7f8fa4;
    font-weight: 300;
    margin-bottom: 0;
}

.form__control {
    width: 100%;
    border-radius: 4px;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    font-weight: 400;
    padding: 5px 12px;
    outline: none;
    display: block;
    font-size: 14px;
}

.form__validation {
  font-size: 12px;
  display: inline-block;
  color: #d9534f;
  font-weight: 300;
}

.login__button {
    padding: 0px 14px 15px 14px;
}

.signin-btn {
    width: 100%;
    background-color: #006BB6;
    color: #fff;
    height: 40px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

/* Switch */
.switch-text {
  margin-right: 15px;
  height: 18px;
  vertical-align: super;
  display:inline-block;
  font-size: 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #009370;
}

input:focus + .slider {
  box-shadow: 0 0 1px #009370;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.is_valid { color:#009370; text-decoration: line-through;}
.is_valid:before { width: 100%; }

</style>
