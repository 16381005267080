<template>
  <div id="app">
    <div v-if="showMenu">
      <!-- <div v-if="showMessage" class="alert alert-info alert-dismissible fade show" role="alert" style="z-index: 1045; width: 800px; margin: 0 auto; text-align:left;">
        <h4 class="alert-heading">3ConneX Server Location</h4>
        <button type="button" class="close" @click="closeMessage" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <p>We have moved the location of our 3ConneX application from Rackspace to Amazon AWS. You should not see any downtime, if you do please clear your browser cache.</p>
        <hr>
        <p class="mb-0">If you have any questions please email us at <a href="mailto:support@simpsonhealthcare.com">support@simpsonhealthcare.com</a>.</p>
      </div> -->

      <header class="navbar fixed-top navbar-expand navbar-light flex-column flex-md-row bd-navbar" style="background-color: #fff; border-bottom: 1px solid #ccc;">
        <a class="navbar-brand mr-0 mr-md-2" href="/">
          <img :src="clientData.client_logo" height="50"/>
        </a>
        <div class="collapse navbar-collapse pl-5" id="navbarNavDropdown">
          <ul class="navbar-nav mr-auto">
            <!-- <li class="nav-item" v-for="(item, index) in clientData.nav_items" :key="index"> -->
              <li class="nav-item" v-for="(item, index) in navItems" :key="index">
              <router-link v-if="item.type == 'link' && connexModules.includes(item.name)" :to="clientData.client == 'KalVista' || clientData.client == 'SGH' || clientData.client == 'GSK' ? '/sgh/heatmap' : item.link" class="nav-link" active-class="active" @click="checkLinkStatus(item.link)" v-html="formatNavLink(item.name)"></router-link>
            </li>
          </ul>
        </div>
        <ul class="navbar-nav ml-md-auto">
          <li class="nav-item mr-4" v-for="(item, index) in clientData.nav_items" :key="index">
            <a v-if="item.position == 'right' && item.type == 'modal'" href="javascript:void();" class="nav-item nav-link" @click="showModalForm(item)">{{item.name}}</a>
          </li>
          <li class="nav-item mr-4" v-if="hasCrossBrandAccess">
            <multiselect class="mt-2" :options="brands" track-by="name" label="name" :allow-empty="true" :show-labels="false" placeholder="Select Brand..." v-model="selectedBrand" @select="getSelectedData"></multiselect>
          </li>
          <li class="nav-item dropdown">
            <a href="#" class="nav-item nav-link dropdown-toggle mr-md-2" data-toggle="dropdown">{{user}}</a>
            <div class="dropdown-menu dropdown-menu-md-right">
              <a href="javascript:void();" class="dropdown-item" @click="logout">Logout</a>
            </div>
          </li>
        </ul>
      </header>

      <div v-if="showFormModal">
        <form-modal @close="closeFormModal" @save="saveForm" :clientData="clientData" :data="formData" :title="formTitle"/>
      </div>
    </div>

    <router-view />
  </div>
</template>
<script>
import FormModal from '@/components/FormModal.vue'
import AuthService from '@/services/auth.service'
import axios from 'axios'
export default {
  components: {
    FormModal
  },
  data() {
    return {
      clientData: {},
      user: '',
      showFormModal: false,
      formTitle: '',
      formData: {},
      showMessage: false,
      connexModules: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).connex_modules : {},
      hasCrossBrandAccess: false,
      navItems: [{
            "name": "Experts",
            "link": "/experts",
            "type": "link"
        },{
            "name": "Heatmap",
            "link": "/heatmap",
            "type": "link"
        },{
            "name": "Congress",
            "link": "/congress",
            "type": "link"
        },{
            "name": "Interactions",
            "link": "/ese-interactions",
            "type": "link"
        }
        
      ],
      brands: [],
      selectedBrand: {},
      brandChanged: false
    }
  },
  computed: {
    showMenu: function () {
      return (this.$route.name !== 'Login' && this.$route.name !== 'forgot-password' && this.$route.name !== 'change-password')
    }
    
  },
  methods: {
    getData: function () {
      if(localStorage.getItem('project')) {
        let project = localStorage.getItem('project')
        // console.log(project)
        axios.get(`https://simpsonapi.com/v2/conventions/3x-data/${project}`).then(res => {
          console.log(res)
          if(res.data) {
            this.clientData = res.data.data  
            console.log('client', this.clientData)
            this.getBrandData(project)
          }
          
        })
      }

    },
    formatNavLink: function(item) {
      return item == 'Heatmap' ? `HeatMap&trade;` : item
    },
    logout: function () {
      localStorage.removeItem('project')
      localStorage.removeItem('name')
      localStorage.removeItem('access_type')
      localStorage.removeItem('user')
      localStorage.removeItem('settings')
      localStorage.removeItem('token')
      localStorage.removeItem('message')
      this.$router.push('/')
    },
    showModalForm: function (item) {
      this.formTitle = item.name
      this.showFormModal = true
      this.formData = item
    },
    closeFormModal: function () {
      this.showFormModal = false
      this.formData = {}
    },
    saveForm: function (item) {
      this.showFormModal = false
    },
    closeMessage: function () {
      localStorage.setItem('message', false);
      this.showMessage = false;
    },
    getBrandData: function(project) {
      this.brands = JSON.parse(localStorage.getItem('user')).hasCrossBrandAccess ? JSON.parse(localStorage.getItem('user')).crossBrands : []
      const brands = this.brands.filter(obj => {
        return obj.project_id == project
      })
      this.selectedBrand = brands[0]
      console.log(this.selectedBrand)
    },
    checkLinkStatus: function(link) {
      console.log(link)
    },
    getSelectedData: function(data) {
      this.$router.go()
    }
  },
  watch: {
    '$route' (to, from) {
      if(to.name !== 'Login') {
        this.getData()
        this.user = localStorage.getItem('name')
        
        if(localStorage.getItem('user')){
          this.connexModules = JSON.parse(localStorage.getItem('user')).connex_modules
          this.hasCrossBrandAccess = JSON.parse(localStorage.getItem('user')).hasCrossBrandAccess
        }
      }
      document.title = to.meta.title || '3ConneX'
    },
    'selectedBrand' (newValue) {
      this.hasCrossBrandAccess = JSON.parse(localStorage.getItem('user')).hasCrossBrandAccess
      if(newValue !== undefined) {
        console.log(newValue)
        localStorage.setItem('project', newValue.project_id)
    
        // console.log('project', localStorage.getItem('project'))
        axios.get(`https://simpsonapi.com/v2/conventions/3x-data/${newValue.project_id}`).then(res => {
          console.log(res)
          if(res.data) {
            localStorage.setItem('settings', JSON.stringify(res.data))
          }
        })
        // console.log('settings', JSON.parse(localStorage.getItem('settings')))
        // console.log(this.$route.name, this.$route.path)
      }

    }
  },
  mounted () {
    this.user = localStorage.getItem('name')

 
    if(localStorage.getItem('message') === 'false') {
      this.showMessage = false;
    } else {
      this.showMessage = true;
      localStorage.setItem('message', true);
    }
    
    this.getData()
  }
}
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #000;
  background-color: #ccc;
  padding: 15px;
}

.navbar-light .right-dropdown .active>.nav-link,
.navbar-light .right-dropdown .nav-link.active,
.navbar-light .right-dropdown .nav-link.show,
.navbar-light .right-dropdown .show>.nav-link {
  color: #000;
  background-color: transparent;
  padding: 15px;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:visited{
  color: #000;
  padding: 15px 20px;
}
</style>
