<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px !important;">
            <h5 class="modal-title">{{title}}</h5>
            <button class="close" v-on:click="close">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body" style="padding: 0px;">
            
            <div class="card" style="height: 500px; overflow-y:auto;">
              <p v-if="data.info_text" class="text-left pt-4 px-4" style="font-weight:bold;">{{data.info_text}}</p>
              <form class="needs-validation" novalidate>
                <div style="padding: 15px;" class="text-left">
                  <b-alert variant="danger" :show="showError">Please fill in all required fields</b-alert>
                  <div v-for="item in data.fields" :key="item.id">
                    <div v-if="item.multi_col_items" class="row">
                      <div v-for="mcol in item.multi_col_items" :key="mcol.id" :class="mcol.column">
                        <!---------- Input ---------->
                        <div class="form-group" v-if="mcol.type == 'input'">
                          <span class="text-danger" v-show="mcol.required">* </span><label>{{mcol.label}}</label>
                          <b-form-datepicker v-if="mcol.input_type == 'date'" today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="inputForm[mcol.vmodel]" :placeholder="mcol.placeholder"></b-form-datepicker>
                          <div class="invalid-feedback">Field is required</div>
                        </div>
                        <!---------- Dropdown ---------->
                        <div class="form-group" v-if="mcol.type == 'dropdown'">
                          <span class="text-danger" v-show="mcol.required">* </span><label>{{mcol.label}}</label>
                          <select class="form-control" v-model="inputForm[mcol.vmodel]">
                            <option>{{ mcol.placeholder }}</option>
                            <option v-for="(choice, index) in mcol.data" :key="index" :value="choice">
                              {{ choice }}
                            </option>
                          </select>
                          <!-- <multiselect :options="mcol.data" :allow-empty="true" :show-labels="false" :placeholder="mcol.placeholder" v-model="engagement_type"></multiselect> -->
                          <!-- <label class="invalid" v-if="isInvalid(mcol)">Field is required</label> -->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div :class="item.column">
                        <!---------- Input ---------->
                        <!-- <div class="form-group" > -->
                          <b-form-group v-if="item.type == 'input'" :id="`input-${item.id}`">
                            <span class="text-danger" v-show="item.required">* </span><label>{{item.label}}</label>
                            <!-- <input :type="item.inputType" class="form-control" :placeholder="item.placeholder" v-model="inputForm[item.vmodel]"> -->
                            <b-form-input :id="`input-live-${item.id}`" :type="item.inputType" v-model="inputForm[item.vmodel]" :aria-describedby="`input-live-feedback-${item.id}`" :state="inputState(inputForm[item.vmodel])" :placeholder="item.placeholder" trim></b-form-input>
                            <!-- <div class="invalid-feedback">Field is required and enter text only</div> -->
                            <b-form-invalid-feedback :id="`input-live-feedback-${item.id}`">
                              Field is required and enter text only
                            </b-form-invalid-feedback>
                          </b-form-group>
                 
                        <!-- </div> -->

                        <!--------- KOL  --------->
                        <div class="form-group" v-if="item.type == 'kol'">
                          <span class="text-danger" v-show="item.required">* </span><label>{{item.label}}</label>
                          <select class="form-control" v-model="inputForm[item.vmodel]">
                            <option value="">{{ item.placeholder }}</option>
                            <option v-for="(option, index) in items" :key="index" :value="option">
                              {{ option.first_name }} {{ option.last_name }}
                            </option>
                          </select>
                        </div>
    
                        <!---------- Textarea ---------->
                        <b-form-group v-if="item.type == 'textarea'">
                          <span class="text-danger" v-show="item.required">* </span><label>{{item.label}}</label>
                          <b-form-textarea
                            id="textarea"
                            v-model="inputForm[item.vmodel]"
                            :placeholder="item.placeholder"
                            rows="3"
                          ></b-form-textarea>
                        </b-form-group>

                        <!---------- Dropdown ---------->
                        <div class="form-group" v-if="item.type == 'dropdown'">
                          <span class="text-danger" v-show="item.required">* </span><label>{{item.label}}</label>
                          <select class="form-control" v-model="inputForm[item.vmodel]">
                            <option value="">{{ item.placeholder }}</option>
                            <option v-for="(option, index) in item.data" :key="index" :value="option">
                              {{ option }}
                            </option>
                          </select>
                          <!-- <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :placeholder="item.placeholder" v-model="tone_of_interaction"></multiselect> -->
                          <!-- <label class="invalid" v-if="isInvalid(item)">Field is required</label> -->
                        </div>

                        <!---------- Dropdown Belief ---------->
                        <div class="form-group" v-if="item.type == 'dropdown-belief'">
                          <span class="text-danger" v-show="item.required">* </span><label>{{item.label}}</label>
                          <select class="form-control" v-model="inputForm[item.vmodel]" @change="showList(inputForm[item.vmodel])">
                            <option value="">{{ item.placeholder }}</option>
                            <option v-for="(option, index) in item.data" :key="index" :value="option">
                              {{ option }}
                            </option>
                          </select>
                          <!-- <multiselect :options="item.data" :allow-empty="true" :show-labels="false" :placeholder="item.placeholder" v-model="tone_of_interaction"></multiselect> -->
                          <!-- <label class="invalid" v-if="isInvalid(item)">Field is required</label> -->
                          <!---------- Dropdown Belief ---------->
                          <div class="form-group pt-4" v-if="showFarxiga">
                            <div style="background-color: #f2f2f2;" class="p-2">
                              <label>Belief Name</label>
                              <select class="form-control" v-model="farxiga.belief_name">
                                <option value="">Select...</option>
                                <option value="There is urgency to initiate therapy in appropriate patients with CKD.">There is urgency to initiate therapy in appropriate patients with CKD.</option>
                                <option value="There are differences between DAPA-CKD data and EMPA-KIDNEY data.">There are differences between DAPA-CKD data and EMPA-KIDNEY data.</option>
                                <option value="Advocate publicly for the appropriate use of FARXIGA in patients with CKD to both nephrologists and PCPs.">Advocate publicly for the appropriate use of FARXIGA in patients with CKD to both nephrologists and PCPs.</option>
                              </select>
                              <br>
                              <div class="d-flex justify-content-start align-items-center">
                                <div>
                                  <label>Belief Value</label>
                                  <input type="number" min="0" max="5" value="0" class="form-control" style="width: 100px; margin-right: 10px;" v-model="farxiga.belief_value" :disabled="isFarxigaChange">
                                </div>
                                <div style="padding-left: 10px;">
                                  <h4 style=" font-size: 1rem; color: rgb(44, 62, 80); font-weight: 400; line-height: 1.5;">No Change</h4>
                                  <label class="switch">
                                      <input type="checkbox" class="form-control" v-model="isFarxigaChange" @change="farxigaNoChange()">
                                      <div class="slider round"></div>
                                  </label>
                                </div>
                              </div>                              
                              <br>
                              <label>Comments</label>
                              <textarea class="form-control" v-model="farxiga.belief_description">Enter comments...</textarea>
                              <br>
                              <button class="btn btn-primary" @click.stop.prevent="addFarxiga(farxiga)">Add</button>
                            </div>
                            <div class="pt-4">
                              <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center" v-for="value in farxigaList" :key="value.belief_name">
                                  <div>
                                    {{value.belief_name}}<br>
                                    <small>{{value.belief_description}}</small>
                                  </div>
                                  <span class="badge badge-primary badge-pill">{{value.belief_value}}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="form-group pt-4" v-if="showLokelma">
                            <label>Belief Name</label>
                            <select class="form-control" v-model="lokelma.belief_name">
                              <option value="">Select...</option>
                              <option value="LOKELMA supports my efforts to treat patients with CKD and optimize RAASi.">LOKELMA supports my efforts to treat patients with CKD and optimize RAASi.</option>
                              <option value="Long-term binder use is essential to enable my efforts to appropriately manage my patients with CKD who have experienced 2 or more HK events.">Long-term binder use is essential to enable my efforts to appropriately manage my patients with CKD who have experienced 2 or more HK events.</option>
                              <option value="LOKELMA is my binder treatment of choice for hyperkalemia in patients with CKD.">LOKELMA is my binder treatment of choice for hyperkalemia in patients with CKD.</option>
                            </select>
                            <br>
                            <div class="d-flex justify-content-start align-items-center">
                              <div>
                                <label>Belief Value</label>
                                <input type="number" min="0" max="5" value="0" class="form-control" style="width: 100px; margin-right: 10px;" v-model="lokelma.belief_value" :disabled="isLokelmaChange">
                              </div>
                              <div style="padding-left: 10px;">
                                <h4 style=" font-size: 1rem; color: rgb(44, 62, 80); font-weight: 400; line-height: 1.5;">No Change</h4>
                                <label class="switch">
                                    <input type="checkbox" class="form-control" v-model="isLokelmaChange" @change="lokelmaNoChange()">
                                    <div class="slider round"></div>
                                </label>
                              </div>
                            </div>
                            <br>
                            <label>Comments</label>
                            <textarea class="form-control" v-model="lokelma.belief_description">Enter comments...</textarea>
                            <br>
                            <button class="btn btn-primary" @click.stop.prevent="addLokelma(lokelma)">Add</button>
                            <div class="pt-4">
                              <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center" v-for="value in lokelmaList" :key="value.belief_name">
                                  <div>
                                    {{value.belief_name}}<br>
                                    <small>{{value.belief_description}}</small>
                                  </div>
                                  <span class="badge badge-primary badge-pill">{{value.belief_value}}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                  <div class="p-3 d-flex justify-content-end">
                    <div class="p-1 d-flex">
                      <button type="submit" class="btn btn-primary mr-3" @click.stop.prevent="saveForm">Save</button>
                      <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
export default {
  name: 'FormModal',
  props: ['clientData', 'title', 'data'],
  data () {
    return {
      inputForm: {},
      formArray: [],
      formSubmitted: false,
      formValid: false,
      value: [],
      items: [],
      formMounted: false,
      dpvmodel: '',
      showError: false,
      showFarxiga: false,
      showLokelma: false,
      isLokelmaChange: false,
      isFarxigaChange: false,
      farxiga: {},
      farxigaList: [],
      lokelma: {},
      lokelmaList: []
    }
  },
  computed: {
  },
  methods: {
    inputState(item) {
      if(item) {
        var re = /^[A-Za-z]+$/;
        return item.length != 0 && re.test(item) ? true : false
      }
    },
    close: function () {
      this.$emit('close')
    },
    isInvalid (item) {
      if(this.formSubmitted) {
        return (this.inputForm[item.vmodel] === null || this.inputForm[item.vmodel] === '') && item.required ? true : ''
      }
    },
    checkSelect: function(item) {
      if(this.formSubmitted) {
        if ((this.inputForm[item.vmodel] === null || this.inputForm[item.vmodel] === '') && item.required) {
          return 'invalid'
        } else if ((this.inputForm[item.vmodel] !== null || this.inputForm[item.vmodel] !== '') && item.required) {
          return 'valid'
        }
      }
    },
    checkFormField: function (item) {
      if(this.formSubmitted) {
        if ((this.inputForm[item.vmodel] === null || this.inputForm[item.vmodel] === '') && item.required) {
          return 'is-invalid'
        } else if ((this.inputForm[item.vmodel] !== null || this.inputForm[item.vmodel] !== '') && item.required) {
          return 'is-valid'
        }
      }
    },
    farxigaNoChange: function () {
      if(this.isFarxigaChange) {
        this.farxiga.belief_value = 'No Change'
      }
    },
    lokelmaNoChange: function () {
      if(this.isLokelmaChange) {
        this.lokelma.belief_value = 'No Change'
      }
    },
    checkItem: function (item) {
      if ((this.inputForm[item.vmodel] !== '' || this.inputForm[item.vmodel] !== null) && item.required) {
        const index = this.formArray.indexOf(item)
        this.formArray.splice(index, 1)
        this.checkFormField(item)
      } else {
        this.checkFormField(item)
      }
      this.formSubmitted = false
      console.log('Check', this.formArray)
    },
    validateForm: function () {
      
      for (let i=0; i<this.formArray.length - 1; i++) {
        console.log(this.inputForm[this.formArray[i]])
        if(this.inputForm[this.formArray[i]] === null || this.inputForm[this.formArray[i]] === '' ) {
          this.showError = true
          this.formValid = false
          break
        } else {
          this.showError = false
          this.formValid = true
        }
      }
    },
    getKOLs: function () {
      console.log(this.clientData.project_url)
      axios.get(`${this.clientData.project_url}${this.clientData.db_name}/connex-kols`).then(res => {
         console.log(res)
         this.items = res.data.kols
       })
    },
    getPhoto: function (image) {
      // console.log(image)
      return this.clientData.kol_image_url + image
    },

    addFarxiga: function(item) {
      this.farxigaList.push(item)
      this.farxiga = {}
      this.isFarxigaChange = false
    },

    addLokelma: function(item) {
      this.lokelmaList.push(item)
      this.lokelma = {}
      this.isLokelmaChange = false
    },
    showList: function(item) {
      console.log(item)
      if(item == 'FARXIGA') {
        this.showFarxiga = true
        this.showLokelma = false
      } else if(item == 'LOKELMA') {
        this.showLokelma = true
        this.showFarxiga = false
      } else {
        this.showLokelma = false
        this.showFarxiga = false
      }
    },
    saveForm: function () {
      // this.formSubmitted = true
      this.validateForm()
      if (this.formValid) {
        console.log(this.inputForm)
        console.log(this.formArray)
        this.inputForm.submitted_by = localStorage.getItem('name')
        if(this.showFarxiga) {
          this.inputForm.beliefs = JSON.stringify(this.farxigaList)
        }

        if(this.showLokelma) {
          this.inputForm.beliefs = JSON.stringify(this.lokelmaList)
        }
        // console.log(JSON.stringify(this.inputForm))
        axios.post(`${this.clientData.project_url}${this.clientData.db_name}/${this.data.api_endpoint}`, JSON.stringify(this.inputForm)).then(res => {
          console.log(res.data)  
          this.$emit('save', this.inputForm)
        })
      }
    }
  },
  mounted () {
    let dataArray = []
    this.getKOLs()
    forEach(this.data.fields, (item) => {
        if(item.multi_col_items) {
          forEach(item.multi_col_items, (v) => {
            if(v.required) {
              dataArray.push(v.vmodel)
            }
            this.inputForm[v.vmodel] = null;
          })
        } else {
          if(item.required) {
            dataArray.push(item.vmodel)
          }
          this.inputForm[item.vmodel] = null;
        }
        
    })
    this.formSubmitted = false
    this.formArray = dataArray
    console.log(this.formArray)
    console.log(this.inputForm)
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vdp-datepicker input.form-control {
  background-color:#FFF;
}
</style>
<style scoped>

 .modal-mask {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-body {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.modal-title {
  font-size: 1.5em;
  padding-top: 5px;
}

.close:focus {
  outline: none;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.custom__tag {
      display: inline-block;
    padding: 3px 12px;
    background: #f1f1f1;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
}

.option__title {
  padding-left: 5px;
}

.custom__remove {
  padding-left: 5px;
  cursor: pointer;
}

.multiselect__clear {
    position: absolute;
    right: 1px;
    padding-top: 8px;
    height: 50px;
    width: 50px;
    display: block;
    cursor: pointer;
    z-index: 2;
    color: #ccc;
}
</style>
