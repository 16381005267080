<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title">Meeting with {{title}}</h5>
            <div class="d-flex">
              <button class="btn btn-secondary" @click="close">Close</button>
            </div>
          </div>
          <div class="modal-body px-3 pb-4" style="padding: 0px; text-align:left;">
            <div class="row py-3" style="background-color:rgba(0, 0, 0, 0.03);">
              <div class="col">
                <div class="d-flex justify-content-between">
                  <div><span style="padding-left: 9px;">{{formatDate(data.date)}}</span></div>
                  <div><span style="padding-left: 6px;">{{data.quarter}}</span></div>
                  <div><span style="padding-left: 10px;">{{data.activity_type}}</span></div>
                  <div><span style="padding-left: 10px;">{{data.brand}}</span></div>
                </div>
              </div>
            </div>
            <!-- <div class="row py-4">
              <div class="col">
                <h5 style="font-weight: 700;">Location</h5>
                <p>{{data.meeting_location ? data.meeting_location.name : 'Location TBD'}}</p>
                <p v-if="data.meeting_location && data.meeting_location.name == 'Virtual Meeting'">{{data.meeting_location.location}}</p>
              </div>
            </div>-->
            <div class="row py-4">
              <div class="col">
                <div class="row" v-if="isKOLArray(data.kol)">
                  <div class="col-6" v-for="item in data.kol" :key="item.kol_id">
                    <div class="card p-2">
                      <div class="d-flex justify-content-start">
                        <b-img v-bind="mainProps" rounded="circle" :src="`${settings.kol_image_url}${item.photo}`"></b-img>
                        <div class="list-item">
                          <h2>{{item.first_name}} {{item.last_name}}</h2>
                          <h4 class="text-muted">{{item.specialty}}</h4>
                          <div class="pt-2 pr-2">
                            <button class="btn phone-btn">
                              <a :href="`tel:${item.phone}`"><font-awesome-icon icon="phone-alt" class="phone"/></a>
                            </button>
                            <button class="btn email-btn">
                              <a :href="`mailto:${item.email}`"><font-awesome-icon icon="envelope" class="email"/></a>
                            </button>
                            <button class="btn pdf-btn">
                              <a :href="getURL(item)" target="_blank"><font-awesome-icon icon="file-pdf" class="pdf"/></a>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="row">
                  <div class="col">
                    <h5 style="font-weight: 700;">ESE</h5>
                    {{ data.kol }}
                  </div>
                </div>
                
              </div>
            </div>

            <!-- <div class="row py-2">
              <div class="col">
                <h5 style="font-weight: 700;">AZ Attendee Requests</h5>
                <p>Request other personnel to attend: <strong>Yes</strong></p>
                <p>Other Personnel attending: <strong>Commercial, MSL/CSL, Medical Affairs</strong></p>
                <p>Attendee Request: <strong>Greg, Cara, Rob, Matt</strong></p>
              </div>
            </div>-->
            <div class="row py-4">
              <div class="col">
                <h5 style="font-weight: 700;">Interaction Experience</h5>
                <p>{{ data.interaction_experience }}</p>
              </div>
            </div>
            <div class="row py-4">
              <div class="col">
                <h5 style="font-weight: 700;">Attendee(s)</h5>
                <div class="d-flex justify-content-start">
                  <div v-for="item in data.attendees_object" :key="item.public_id" class="d-flex align-items-center mr-3" style="background-color: #ccc; width: 220px; border-top-left-radius: 16px; border-bottom-left-radius: 16px; border-top-right-radius: 20px; border-bottom-right-radius: 20px;">
                    <b-avatar :text="`${item.first_name[0]}${item.last_name[0]}`" class="mr-3"></b-avatar>
                    <span class="mr-auto">{{item.name}}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row py-4">
              <div class="col">
                <h5 style="font-weight: 700;">Objectives</h5>
                <ul style="margin-left: -20px;">
                  <li class="pb-3" v-for="(item, index) in data.objectives" :key="index">{{item}}</li> 
                </ul>
              </div>
            </div> -->

            <div class="row py-4">
              <div class="col">
                <h5 style="font-weight: 700;">Key Insights</h5>
                <div class="comments" v-html="data.key_insights"></div>
              </div>
            </div>

            <!-- <div class="row py-0" v-if="data.is_completed && !data.did_not_show">
              <div class="col">
                <h5 style="font-weight: 700;">Engagement Completion From</h5>
                <div v-for="(value, key, index) in data.completion_form" :key="index">
                  <h6 style="font-weight: bold;">{{capitalizeFirstLetters(key)}}</h6>
                  <div v-if="Array.isArray(value)">
                      <ul>
                        <li v-for="(item, index) in value" :key="index">{{item}}</li>
                      </ul>
                  </div>
                  <p v-else style="padding-bottom: 15px;">{{getValue(value)}}</p>
                </div>
              </div>
            </div> -->
            
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
import { format, parseISO, parse } from 'date-fns'
export default {
  name: 'ViewInteractionModal',
  props: ['data'],
  data () {
    return {
      settings: JSON.parse(localStorage.getItem("settings") || '{}').data,
      mainProps: { width: 50, height: 50, class: 'list-img' },
      title: '',
      kolNameConvention: '',
      inputForm: {},
      formArray: [],
      formSubmitted: false,
      formValid: false,
      value: [],
      formMounted: false,
      dpvmodel: '',
      showError: false
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    // formatTime: function(date) {
    //   if (date == 'TBD') {
    //     return 'TBD'
    //   } else {
    //     const d = new Date(date)
        
    //     let hr = d.getHours();
    //     let min = d.getMinutes();
    //     if (min < 10) {
    //         min = "0" + min;
    //     }
    //     let ampm = "AM";
    //     if( hr >= 12 ) {
    //       ampm = "PM";
    //     }
    //     if( hr > 12 ) {
    //       hr-= 12
    //     }
    //     return `${hr}:${min} ${ampm}`;
    //   }
    // },
    isKOLArray: function(kol) {
      console.log(Array.isArray(kol))
      return Array.isArray(kol)
    },
    getURL: function (item) {
      let url = ''
      if (this.settings.has_multiple_indications) {
        url = `${this.settings.kol_pdf_url}${item.molecule_indication}-pdf.php?id=${item.kol_id}`
      } else {
        url = `${this.settings.kol_pdf_url}${item.kol_id}`
      }
      return url
    },

    // capitalizeFirstLetters: function(str) {
    //   const item = str.replaceAll("_", " ");
    //   console.log(item)
    //   return item.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
    //       return letter.toUpperCase();
    //   })
    // },

    // getValue: function(value) {
    //   if(value === true || value === false) {
    //     return value === true ? 'Yes' : 'No'
    //   } else {
    //     return value
    //   }
    // },

    formatDate: function(date) {
      return format(date, 'EEEE, MMMM dd, yyyy')
      // if (date == 'TBD') {
      //   return 'TBD'
      // } else {
      //   const d = new Date(date.split('-'))
      //   const day = d.getDate();
      //   const month = d.toLocaleString('default', { month: 'long' });
      //   const year = d.getFullYear();
      //   const dayName = d.toLocaleString('default', { weekday: 'long' });

      //   return `${dayName}, ${month} ${day}, ${year}`
      // }
    }
  },
  mounted () {
    console.log(this.data)
    const kols = this.data.kol.map(x => `${x.first_name} ${x.last_name}`)
    this.title = kols.join(', ')
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vdp-datepicker input.form-control {
  background-color:#FFF;
}
</style>
<style scoped>

.modal-mask {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-content {
  height: calc(100vh - 60px);
}

.modal-body {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.modal-title {
  font-size: 1.5em;
  padding-top: 5px;
}


.close:focus {
  outline: none;
}

.list-item {
  padding-left: 16px;
  margin-top: 8px;
}

.list-item > h2 {
  font-size: 22px;
  margin-bottom: 0px;
}

.list-item > h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.list-item > p {
  font-size: 14px;
  margin-bottom: 0px;
}

.list-img {
  margin-top: 5px
}

.phone-btn {
  background: #EAF0FF;
  border-radius: 4px;
  padding: 5px 10px;
}
.phone {
  font-size: 20px;
  color: #477BFB;
}

.email-btn {
  background: #bbdbcd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 15px;
  margin-right: 15px;
}
.email {
  font-size: 20px;
  color: #239470;
}

.pdf-btn {
  background: #ffc1ae;
  border-radius: 4px;
  padding: 5px 10px;
}
.pdf {
  font-size: 20px;
  color: #ff2116;
}

.comments {
  white-space: pre-wrap;
}
</style>
