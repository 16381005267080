import Vue from 'vue'
import VueRouter from 'vue-router'
import Heatmap from '@/views/Heatmap.vue'
import SGHHeatmap from '@/views/SGHHeatmap.vue'
import Profiles from '@/views/Profiles.vue'
import Dashboard from '@/views/Dashboard.vue'
import Congress from '@/views/Congress.vue'
import CongressView from '@/views/CongressView.vue'
import Login from '@/views/Login.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import Interactions from '@/views/Interactions.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: "Login"
    },
  },
  {
    path: '/login/:email?',
    name: 'Login',
    component: Login,
    meta: {
      title: '3ConneX Login'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      title: '3ConneX Forgot Password'
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword,
    meta: {
      title: '3ConneX Change Password'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: '3ConneX Dashboard'
    }
  },
  {
    path: '/experts',
    name: 'Profiles',
    component: Profiles,
    meta: {
      title: '3ConneX Experts'
    }
  },
  {
    path: '/heatmap',
    name: 'Heatmap',
    component: Heatmap,
    meta: {
      title: '3ConneX Heatmap'
    }
  },
  {
    path: '/congress',
    name: 'Congress',
    component: Congress,
    meta: {
      title: '3ConneX Congress'
    }
  },
  {
    path: '/ese-interactions',
    name: 'Interactions',
    component: Interactions,
    meta: {
      title: '3ConneX Interactions'
    }
  },
  {
    path: '/congress/:name',
    name: 'CongressView',
    component: CongressView,
    meta: {
      title: '3ConneX Congress'
    }
  },
  {
    path: '/sgh/heatmap',
    name: 'SGHHeatmap',
    component: SGHHeatmap,
    meta: {
      title: '3ConneX Heatmap'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  next();
  
  // if(window.matchMedia("only screen and (max-width: 760px)").matches) {
  //   window.location.href = 'https://app.3connex.com/mobile'
  // } else {
  //   next()
  // }
});



export default router
