<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-6">
        <div class="input-group mb-4 border rounded p-1">
          <input type="search" placeholder="Which meeting location your searching for?" aria-describedby="button-addon3" class="form-control pl-3 bg-none border-0" style="outline: none; box-shadow: none;" v-model="searchLocation">
          <div class="input-group-append border-0">
            <button id="button-addon3" type="button" class="btn btn-link text-secondary" style="outline: none; box-shadow: none;"><i class="fa fa-search"></i></button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-6 my-3" v-for="item in filteredList" :key="item.location_id">
        <div class="card" @click="viewLocationDetail(item)" style="cursor:pointer;">
          <div class="text-left row justify-content-start py-2 ml-0">
            <div class="list-item py-2">
              <h2>{{item.name}}</h2>
              <h4 class="text-muted"><span style="color:goldenrod; font-weight: 600;">{{item.price}}</span> &bull; {{item.location_type}} &bull; Takes Reservation: <span class="badge" :class="{ 'badge-success': item.takes_reservations == true, 'badge-danger': item.takes_reservations == false }">{{item.takes_reservations ? 'Yes' : 'No'}}</span></h4>
              <p class="text-muted">{{item.distance}} Miles</p>  
            </div>
          </div>
        </div>
      </div>
    </div>
    <location-modal v-if="showLocation" :location-id="locationData" @close="closeLocationModal"></location-modal>
  </div>
</template>

<script>
import { http, authHeader } from '@/config/base-url'
import LocationModal from '../components/LocationModal.vue'
export default {
  name: 'CongressLocationView',
  components: {
    LocationModal
  },
  data() {
    return {
      showLocation: false,
      conventionId: this.$route.params.name,
      meetingLocations: [],
      searchLocation: '',
      locationData: {}
    }
  },
  computed: {
    filteredList() {
      return this.meetingLocations.filter(location => {
        return location.name.toLowerCase().includes(this.searchLocation.toLowerCase())
      })
    }
  },
  methods: {
    viewLocationDetail: function(item) {
      this.showLocation = true
      this.locationData = item.location_id
      document.body.classList.add("modal-open");
    },
    closeLocationModal: function() {
      this.showLocation = false
      setTimeout(() => {
        document.body.classList.remove("modal-open");
      }, 200)
    },
    getLocations: function() {
      http.get(`v2/conventions/${this.conventionId}/meeting-locations`, {
        headers: authHeader
      }).then((res) => {
        console.log(res.data.meeting_locations)
        this.meetingLocations = res.data.meeting_locations
      })
    }
  },
  mounted () {
    this.getLocations()
  }
  // updated () {
  //   this.getData()
  // }
}
</script>
<style scoped>
body.modal-open {
  overflow: hidden;
}

.header {
  padding-bottom: 16px;
}
.custom-card {
  border-top: 1px solid rgba(0,0,0,.125);
}

.add-btn {
  height: 40px;
  margin-top: 15px;
  font-weight: 600;
}

.back-icon {
  font-size: 22px;
  margin-top: 12px;
  margin-left: 16px;
  cursor: pointer;
  color: #000;
}

.title {
  padding-left: 15px;
}

.title > h3 {
  margin-bottom: 0px;
  font-family: Helvetica;
  font-weight: bold;
}

.title > small {
  font-size: 14px;
  margin-top: 0px;
}

.list-item {
  padding-left: 16px;
}

.list-item > h2 {
  font-size: 22px;
  margin-bottom: 0px;
}

.list-item > h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.list-item > p {
  font-size: 14px;
  margin-bottom: 0px;
}

.list-img {
  margin-top: 5px
}

.list-group-item+.list-group-item {
    border-top-width: 1px;
}

.interaction-type {
  margin-bottom: 16px;
}

.flight-types {
  display: flex;
  max-width: 336px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 3px;
  color: #000;
  font-weight: bold;
}
.flight-types > input {
  display: none;
}
.flight-types > input:checked + label {
  color: #fff;
}
.flight-types > input:nth-of-type(1):checked ~ label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
}
.flight-types > input:nth-of-type(2):checked ~ label:last-of-type:before {
  transform: translateX(calc(100% + 0px));
}
.flight-types > input:nth-of-type(3):checked ~ label:last-of-type:before {
  transform: translateX(calc(200% + 0px));
}
.flight-types > input:nth-of-type(4):checked ~ label:last-of-type:before {
  transform: translateX(calc(300% + 0px));
}
.flight-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.flight-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(25% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
}
.flight-types label {
  padding: 6px 3px;
  margin-bottom: 0px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:before {
  background: #006bb6;
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:not(:last-child) {
  border-right: 1px solid #000;
}
</style>
