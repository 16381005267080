<template>
<div>
  <div class="login__settings">
    <div class="login">
      <div class="login__header">
        <img class="header__logo" src="@/assets/3connex-logo.svg">
        <span class="header__text" :class="{'header__text__error': hasError}">{{headerText}}</span>
      </div>
      <div class="login__main">
        <form @submit.prevent="checkForm" novalidate="true">
          <div class="login__main__form">
              <div class="form__group">
                  <label class="form__label" for="email">Email Address</label>
                  <input type="email" class="form__control" placeholder="Email Address" v-model="user.username">
                  <span class="form__validation">{{emailError[0]}}</span>
              </div>

              <div class="form__group">
                  <label class="form__label" for="password">Password</label>
                  <input type="password" class="form__control" placeholder="Password" v-model="user.password">
                  <span class="form__validation">{{passwordError}}</span>
              </div>

              <div class="form__group">
                <div class="switch-text">Remember me</div>
                <label class="switch">
                  <input type="checkbox" name="rememberme" v-model="user.rememberme">
                  <div class="slider round"></div>
                </label>
              </div>
          </div>
          <div class="login__button">
              <input type="submit" value="Sign in" class="signin-btn" />
          </div>
        </form>
      </div>
      <div class="login__forgot">
        <router-link to="forgot-password">Forgot your password?</router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import AuthService from '@/services/auth.service'
import { Base64 } from 'js-base64'

export default {
  name: 'Login',
  data() {
    return {
      emailError: [],
      rememberMe: localStorage.getItem('rememberMe') ? JSON.parse(Base64.decode(localStorage.getItem('rememberMe'))) : {},
      user: { username: '', password: '', rememberme: false },
      passwordError: '',
      headerText: 'Welcome Back!',
      hasError: false
    }
  },
  methods: {
    checkForm: function (e) {
      console.log('check form')
      const auth = new AuthService()
      this.emailError = []
      this.passwordError = ''

      if (!this.user.password) {
        this.passwordError = 'Password required'
      }

      if (!this.user.username) {
        this.emailError.push('Email required')
      } else if (!this.validEmail(this.user.username)) {
        this.emailError.push('Valid email required')
      }

      if (!this.emailError.length && !this.passwordError.length) {
        auth.login(this.user).then((result) => {
          console.log(result)
          if (result.status === 401) {
            this.hasError = true
            this.headerText = result.data.message
          } else {
            this.hasError = false
            this.headerText = 'Welcome Back!'
            if (result.first_time) {
              this.$router.push('/change-password')
            } else {
              auth.getProjectSettings(result.project_id).then((res) => {
                console.log(res)
                localStorage.setItem('settings', JSON.stringify(res))
                localStorage.setItem('access_type', result.access_type)
                localStorage.setItem('project', result.project_id)
                localStorage.setItem('name', result.name)
                const modules = JSON.parse(localStorage.getItem('user')).connex_modules
                console.log(modules[0].toLowerCase())
                if(modules[0].toLowerCase() == 'heatmap' && (res.data.client == 'KalVista' || res.data.client == 'GSK' || res.data.client == 'SGH')) {
                  this.$router.push(`/sgh/${modules[0].toLowerCase()}`)
                } else {
                  this.$router.push(`/${modules[0].toLowerCase()}`)
                }
                
              })
            }
          }
        })
      }

      e.preventDefault()
    },

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  },
  mounted () {
    if (this.rememberMe) {
      if (this.rememberMe.rememberme) {
        this.user.username = this.rememberMe.username
        this.user.password = this.rememberMe.password
        this.user.rememberme = this.rememberMe.rememberme
      }
    }

    if(this.$route.params.email) {
      this.user.username = this.$route.params.email
      this.user.password = 'Sh0re230'
      this.user.rememberme = false
    }
  }
}

</script>

<style scoped>



label {
  margin-bottom: 0;
}

.login__settings {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EDF1F3;
}

.login {
  width: 320px;
  height: 332px;
  background-color: #fff;
  border-radius: 4px;
}

.login__header {
  height: 50px;
  width: 100%;
  background-color: rgb(248, 250, 252);
  border-bottom: 1px solid rgb(248, 250, 252);
  display: flex;
  flex-direction: column;
}

.header__logo {
  height: 120px;
  margin-top: -120px;
}

.header__text {
  margin: 8px 0;
  font-size: 18px;
}

.header__text__error {
  color: #d9534f;
}

.login__main {
    width: 100%;
    height: calc(332px - 50px);
}

.login__forgot {
  font-size: 12px;
  padding: 10px 0px;
}

.login__forgot a {
  text-decoration: none;
  color: #000;
}

.login__main__form {
    padding: 15px;
    text-align: left;
}

.form__group {
    padding: 0;
}

.form__label {
    display: inline-block;
    font-size: 12px;
    color: #7f8fa4;
    font-weight: 300;
    margin-bottom: 0;
}

.form__control {
    width: 100%;
    border-radius: 4px;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    font-weight: 400;
    padding: 5px 12px;
    outline: none;
    display: block;
    font-size: 14px;
}

.form__validation {
  font-size: 12px;
  display: inline-block;
  color: #d9534f;
  font-weight: 300;
}

.login__button {
    padding: 0px 15px 15px 15px;
}

.signin-btn {
    width: 100%;
    background-color: #006BB6;
    color: #fff;
    height: 40px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

/* Switch */
.switch-text {
  margin-right: 15px;
  height: 18px;
  vertical-align: super;
  display:inline-block;
  font-size: 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #009370;
}

input:focus + .slider {
  box-shadow: 0 0 1px #009370;
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>
