<template>
  <div>
    <div class="row">
      <div class="col-6 my-3">
        <div class="card" @click="viewReport" style="cursor:pointer;">
          <div class="text-left py-2 ml-3">
            <div class="py-2 px-4">
              <div class="d-flex justify-content-between">
                <h4 class="pt-2">My Interaction Report</h4>
                <img src="../assets/pdf.svg" width="50">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 my-3" v-if="hasAccess().includes('follow-up')">
        <div class="card" @click="viewFollowUpReport" style="cursor:pointer;">
          <div class="text-left py-2 ml-3">
            <div class="py-2 px-4">
              <div class="d-flex justify-content-between">
                <h4 class="pt-2">Follow-up Actions Report</h4>
                <img src="../assets/xlsx.svg" width="50">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, authHeader } from '@/config/base-url'
import LocationModal from '../components/LocationModal.vue'
export default {
  name: 'CongressReportView',
  components: {
  },
  data() {
    return {
      conventionId: this.$route.params.name,
      currentUser: JSON.parse(localStorage.getItem("user") || '{}').name
    }
  },
  methods: {
    hasAccess: function() {
      return JSON.parse(localStorage.getItem("user") || '{}').roles
    },
    viewReport: function() {
      window.open(`https://assets.simpsonhealthcare.com/3x/my-interaction-report.php?convention=${this.conventionId}&user=${this.currentUser}`, '_blank');
    },
    viewFollowUpReport: function() {
      const client = JSON.parse(localStorage.getItem("settings") || '{}').client

      if (client == 'Praxis') {
        window.open(`https://assets.simpsonhealthcare.com/3x/praxis-follow-up-actions-report.php?convention=${this.conventionId}`, '_blank');
      } else {
        window.open(`https://assets.simpsonhealthcare.com/3x/follow-up-actions-report.php?convention=${this.conventionId}`, '_blank');
      }
      
    }
  },
  mounted () {

  }
}
</script>
<style scoped>
body.modal-open {
  overflow: hidden;
}

.header {
  padding-bottom: 16px;
}
.custom-card {
  border-top: 1px solid rgba(0,0,0,.125);
}

.add-btn {
  height: 40px;
  margin-top: 15px;
  font-weight: 600;
}

.back-icon {
  font-size: 22px;
  margin-top: 12px;
  margin-left: 16px;
  cursor: pointer;
  color: #000;
}

.title {
  padding-left: 15px;
}

.title > h3 {
  margin-bottom: 0px;
  font-family: Helvetica;
  font-weight: bold;
}

.title > small {
  font-size: 14px;
  margin-top: 0px;
}

.list-item {
  padding-left: 16px;
}

.list-item > h2 {
  font-size: 22px;
  margin-bottom: 0px;
}

.list-item > h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.list-item > p {
  font-size: 14px;
  margin-bottom: 0px;
}

.list-img {
  margin-top: 5px
}

.list-group-item+.list-group-item {
    border-top-width: 1px;
}

.interaction-type {
  margin-bottom: 16px;
}

.flight-types {
  display: flex;
  max-width: 336px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 3px;
  color: #000;
  font-weight: bold;
}
.flight-types > input {
  display: none;
}
.flight-types > input:checked + label {
  color: #fff;
}
.flight-types > input:nth-of-type(1):checked ~ label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
}
.flight-types > input:nth-of-type(2):checked ~ label:last-of-type:before {
  transform: translateX(calc(100% + 0px));
}
.flight-types > input:nth-of-type(3):checked ~ label:last-of-type:before {
  transform: translateX(calc(200% + 0px));
}
.flight-types > input:nth-of-type(4):checked ~ label:last-of-type:before {
  transform: translateX(calc(300% + 0px));
}
.flight-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.flight-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(25% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
}
.flight-types label {
  padding: 6px 3px;
  margin-bottom: 0px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:before {
  background: #006bb6;
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:not(:last-child) {
  border-right: 1px solid #000;
}
</style>
