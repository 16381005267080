<template>
  <div style="padding-top: 100px;">
    <div class="container">

    <div class="d-flex justify-content-between">
      <h2>{{ new Date().getFullYear() }} Interactions</h2>
      <button class="btn btn-primary add-btn" @click="addInteraction"><font-awesome-icon icon="plus"/>&nbsp;Add Interaction</button>
    </div>
    <div class="interaction-type">
      <div class="flight-types">
        <input type="radio" name="flight-type" value="q1" id="q1" v-model="currentTab" @click="showCurrentTab('q1')"/>
        <label for="q1">Q1</label>

        <input type="radio" name="flight-type" value="q2" id="q2" v-model="currentTab" @click="showCurrentTab('q2')"/>
        <label for="q2">Q2</label>

        <input type="radio" name="flight-type" value="q3" id="q3" v-model="currentTab" @click="showCurrentTab('q3')"/>
        <label for="q3">Q3</label>

        <input type="radio" name="flight-type" value="q4" id="q4" v-model="currentTab" @click="showCurrentTab('q4')"/>
        <label for="q4">Q4</label>
      </div>
      <small>{{interactionCount}} interactions</small>
    </div>
    <div v-if="loading" class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div>
      <div v-for="(items, key, index) in allMeetings" :key="index">
        <h4 class="text-left pt-2 mt-2 mb-0">{{formatDate(key)}}</h4>
        <div class="row">
          <div class="col-6 my-3" v-for="(value, i) in sortData(items)" :key="i">
            <div class="card" style="cursor:pointer;">
              <div class="card-body p-1" @click="viewInteractionDetail(value)">
                <div class="text-left row justify-content-start py-2 ml-3">
                  <b-img  v-if="isKOLArray(value.kol)" v-bind="mainProps" rounded="circle" :src="getImg(value.kol[0].photo, value.id)"></b-img>
                  <div class="list-item">
                    <h2 v-if="isKOLArray(value.kol)">{{value.kol[0].first_name}} {{value.kol[0].last_name}}</h2>
                    <h2 v-else>{{value.kol}}</h2>
                    <h4 class="text-muted">{{ value.quarter }} &bullet; {{ value.activity_type }} &bullet; {{ value.brand }}</h4>                 
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-sm btn-outline-primary mr-2" @click="editInteraction(value)"><font-awesome-icon icon="edit"/> Edit</button>
                  <button class="btn btn-sm btn-outline-danger" @click="deleteInteraction(value)"><font-awesome-icon icon="times"/> Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <view-kol-interaction-modal v-if="showInteractionDetail" @close="closeViewInteractionModal" :data="interactionData"></view-kol-interaction-modal>
    <kol-interaction-modal v-if="showInteraction" @close="closeInteractionModal" :edit-data="editData" :title="interactionTitle" :items="items"></kol-interaction-modal>
    <interaction-completed-modal v-if="showInteractionCompleted" :id="interactionId" @close="closeInteractionCompletedModal"></interaction-completed-modal>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
import { http, authHeader } from '@/config/base-url'
import http2 from '@/config/base'
import ViewKolInteractionModal from '../components/ViewKolInteractionModal.vue'
import KolInteractionModal from '../components/KolInteractionModal.vue'
import InteractionCompletedModal from '../components/InteractionCompletedModal.vue'
import { format, parseISO } from 'date-fns'
export default {
  name: 'CongressInteractionListView',
  components: {
    ViewKolInteractionModal,
    KolInteractionModal,
    InteractionCompletedModal
  },
  data() {
    return {
      mainProps: { width: 55, height: 55, class: 'list-img' },
      mainPropsDouble: { width: 35, height: 35, class: 'list-img' },
      hasCompletionForm: false,
      currentTab: `q${Math.floor((new Date().getMonth() + 3) / 3)}`,
      currentUser: JSON.parse(localStorage.getItem("user") || '{}').name,
      meetings: [],
      allMeetings: [],
      interactionCount: '0',
      showInteractionDetail: false,
      showInteraction: false,
      showInteractionCompleted: false,
      conventionId: this.$route.params.name,
      interactionData: '',
      editData: {},
      interactionId: '',
      loading: false,
      interactionTitle: '',
      items: []
    }
  },
  computed: {
    
  },
  methods: {
    
    sortObj: function(obj) {
      return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
    },

    groupBy: function(arr, property) {
      return arr.reduce(function (memo, x) {
          if (!memo[x[property]]) { memo[x[property]] = []; }
          memo[x[property]].push(x);
          return memo;
      }, {});
    },

    sortData: function(items) {
      return items.slice().sort((a,b) => {
        let da = new Date(a.start_time),
            db = new Date(b.start_time);
        return da - db;
      })
    },
    isKOLArray: function(kol) {
      console.log(Array.isArray(kol))
      return Array.isArray(kol)
    },
    updateCompleted: function() {
      this.getData()
    },
    addInteraction: function() {
      this.editData = null
      this.showInteraction = true
      this.interactionTitle = 'Add Interaction'
      document.body.classList.add("modal-open");
    },
    viewInteractionDetail: function(value) {
      this.showInteractionDetail = true
      this.interactionData = value
      document.body.classList.add("modal-open");
    },

    editInteraction: function(item) {
      this.editData = item
      this.showInteraction = true
      this.interactionTitle = 'Edit Interaction'
      document.body.classList.add("modal-open");
    },

    deleteInteraction: function(item) {
      console.log(item)
      http2.delete(`v2/conventions/kols/${item.id}`).then((res) => {
        this.meetings = this.meetings.filter(meeting => meeting.id !== item.id)
        this.getItems()
        // this.interactionCount = res.data.length

      })
    },

    closeViewInteractionModal: function() {
      this.showInteractionDetail = false
      setTimeout(() => {
        document.body.classList.remove("modal-open");
      }, 200)
    },

    closeInteractionModal: function() {
      this.showInteraction = false

      setTimeout(() => {
        this.getData()
        document.body.classList.remove("modal-open");
      }, 200)
    },

    closeInteractionCompletedModal: function() {
      this.showInteractionCompleted = false

      setTimeout(() => {
        this.getData()
        document.body.classList.remove("modal-open");
      }, 200)
    },


    formatTime: function(date) {
      if (date == 'TBD') {
        return 'TBD'
      } else {
        const d = new Date(date)
        
        let hr = d.getHours();
        let min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        let ampm = "AM";
        if( hr >= 12 ) {
          ampm = "PM";
        }
        if( hr > 12 ) {
          hr-= 12
        }
        return `${hr}:${min} ${ampm}`;
      }
    },

    formatDate: function(date) {
      return format(parseISO(date), 'EEEE, MMMM d, yyyy')
    },

    getImg: function(image, id) {
      const links = JSON.parse(localStorage.getItem("settings") || '{}').data;
      console.log(`${links.kol_image_url}${image} - ${id}`)
      return `${links.kol_image_url}${image}`;
    },

    showCurrentTab: function(tab) {
      this.currentTab = tab
      this.getItems()
    },

    getItems: function() {
      this.loading = true
      switch(this.currentTab) {
        case 'q1': {
            let items = this.meetings.filter(object => object.quarter === 'Q1')
            this.allMeetings = this.groupBy(items, 'date')
            let count = 0
            Object.keys(this.allMeetings).forEach(el => {
              count = count + this.allMeetings[el].length
            })
            this.interactionCount = count
          }
          break;
        case 'q2': {
            let items = this.meetings.filter(object => object.quarter === 'Q2')
            this.allMeetings = this.groupBy(items, 'date')
            let count = 0
            Object.keys(this.allMeetings).forEach(el => {
              count = count + this.allMeetings[el].length
            })
            this.interactionCount = count
          }
          break;
        case 'q3': {
            let items = this.meetings.filter(object => object.quarter === 'Q3')
            this.allMeetings = this.groupBy(items, 'date')
            let count = 0
            Object.keys(this.allMeetings).forEach(el => {
              count = count + this.allMeetings[el].length
            })
            this.interactionCount = count
          }
          break;
        case 'q4': {
            let items = this.meetings.filter(object => object.quarter === 'Q4')
            this.allMeetings = this.groupBy(items, 'date')
            let count = 0
            Object.keys(this.allMeetings).forEach(el => {
              count = count + this.allMeetings[el].length
            })
            this.interactionCount = count
          }
          break;
      }
      
      // console.log(this.allMeetings)
      this.loading = false
    },

    getData: function() {
      http2.get(`v2/conventions/kols/interactions`).then((res) => {
        this.meetings = res.data.kols
        console.log(this.meetings)
        this.getItems()
        // this.interactionCount = res.data.length

      })
    },

    getDataKOLs: function () {
       axios.get(`https://simpsonapi.com/v2/conventions/3x-data/${localStorage.getItem('project')}`).then(res => {
         console.log(res)
         this.clientData = res.data.data
         this.getKOLs()
       })
    },
    getKOLs: function () {
      console.log(this.clientData.project_url)
      axios.get(`${this.clientData.project_url}${this.clientData.db_name}/kols`).then(res => {
         console.log(res)
         this.items = res.data.kols
       })
    },
  },
  mounted () {
    this.getData()
    this.getDataKOLs()
  }
  // updated () {
  //   this.getData()
  // }
}
</script>
<style scoped>
body.modal-open {
  overflow: hidden;
}

.header {
  padding-bottom: 16px;
}
.custom-card {
  border-top: 1px solid rgba(0,0,0,.125);
}

.add-btn {
  height: 40px;
  font-weight: 600;
}

.back-icon {
  font-size: 22px;
  margin-top: 12px;
  margin-left: 16px;
  cursor: pointer;
  color: #000;
}

/* .card {
  height: 115px;
} */

.created-css {
  margin-top: -10px;
}

.other-tab-css {
  margin-top: 14px;
}

.created-css-2 {
  margin-top: -20px;
}

.other-tab-css-2 {
  margin-top: -6px;
}

.title {
  padding-left: 15px;
}

.title > h3 {
  margin-bottom: 0px;
  font-family: Helvetica;
  font-weight: bold;
}

.title > small {
  font-size: 14px;
  margin-top: 0px;
}

.list-item {
  margin-top: 10px;
  padding-left: 16px;
}

.list-item > h2 {
  font-size: 22px;
  margin-bottom: 0px;
}

.list-item > h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.list-item > p {
  font-size: 14px;
  margin-bottom: 0px;
}

.list-img {
  margin-top: 5px
}

.list-group-item+.list-group-item {
    border-top-width: 1px;
}

.interaction-type {
  margin-bottom: 16px;
}

.flight-types {
  display: flex;
  max-width: 336px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 3px;
  color: #000;
  font-weight: bold;
}
.flight-types > input {
  display: none;
}
.flight-types > input:checked + label {
  color: #fff;
}
.flight-types > input:nth-of-type(1):checked ~ label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
}
.flight-types > input:nth-of-type(2):checked ~ label:last-of-type:before {
  transform: translateX(calc(100% + 0px));
}
.flight-types > input:nth-of-type(3):checked ~ label:last-of-type:before {
  transform: translateX(calc(200% + 0px));
}
.flight-types > input:nth-of-type(4):checked ~ label:last-of-type:before {
  transform: translateX(calc(300% + 0px));
}
.flight-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.flight-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(25% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
}
.flight-types label {
  padding: 6px 3px;
  margin-bottom: 0px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:before {
  background: #006bb6;
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:not(:last-child) {
  border-right: 1px solid #000;
}
</style>
