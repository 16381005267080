<template>
  <div style="padding-top: 100px;">
    <div>
      <b-container>
        <b-row class="mt-2">
          <b-col sm="12" md="6" lg="4" class="mt-3 mb-2" v-for="item in conventions" :key="item._id">
            <router-link :to="`/congress/${item.convention_id}`" class="card-link">
              <b-card overlay align="left" text-variant="white" footer-text-variant="dark" footer-bg-variant="white" :title="item.convention_details.name" :img-src="item.convention_details.location.image" :img-alt="item.convention_details.name" img-top>
                <b-card-text>{{formatDate(item.convention_details.start_date)}} - {{formatDate(item.convention_details.end_date)}}</b-card-text>
                <template #footer>
                  <h6>{{item.convention_details.location.name}}</h6>
                  <p class="text-muted">{{item.convention_details.location.address}}, {{item.convention_details.location.city}}, {{item.convention_details.location.state}} {{item.convention_details.location.zip}}</p>
                </template>
              </b-card>
            </router-link>
          </b-col>
        </b-row>
        <!-- <b-row v-if="congressType == 'virtual'" class="mt-2">
          <b-col sm="12" md="6" lg="4" class="mt-3 mb-2" v-for="item in virtualConventions" :key="item._id">
            <b-link href="#" class="card-link">
              <b-card overlay align="left" style="background: #f0f0f0;" footer-text-variant="dark" footer-bg-variant="white" img-src="https://imgs.simpsonhealthcare.com/conventions/virtual-interviews.png" :img-alt="item.name" img-top>
                <template #footer>
                  <h6>{{item.name}}</h6>
                  <p class="text-muted">{{formatDate(item.start_date)}} - {{formatDate(item.end_date)}}</p>
                </template>
              </b-card>
            </b-link>
          </b-col>
        </b-row> -->
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AccordionForms from '@/components/AccordionForms.vue'
import ConventionService from '@/services/convention.service'
import { forEach } from 'lodash'

export default {
  name: 'Congress',
  components: {
		AccordionForms
  },
  data() {
    return {
      publicId: JSON.parse(localStorage.getItem("user") || '{}'),
      congressType: 'live',
      conventions: [],
    }
  },
  computed: {
  },
  methods: {
    getUserConventions: function () {
      // console.log(this.publicId.publicId)
      const id = this.publicId.publicId
      axios.get(`https://simpsonapi.com/v2/conventions/user/${id}`, {
        headers: {
          'X-Access-Token': `${localStorage.getItem('token')}`
        }
      }).then((res) => {
        console.log('convention', res.data)
        this.conventions = res.data
      }).catch((error) => {
        console.log(error)
      })
      // ConventionService.getUserConventions(id).then((response) => {
      //   console.log('user convention', response)
      //   // this.getConventions(response)
      // })
      // http2.get(`v2/conventions/user/${this.publicId.publicId}`).then((res) => {
      //   const items = res.data
      //   console.log(items)
      // })

    },
    // getConventions: function (data) {
    //   data.forEach((item) => {
    //     console.log(item)
    //     http2.get(`v2/conventions/${item.convention_id}`).then((res) => {
    //       console.log(res.data)
    //       if(res.data.congress_type == 'Live'){
    //         this.liveConventions.push(res.data)
    //       } else {
    //         this.virtualConventions.push(res.data)
    //       }
    //     })
    //   });
    // },
    formatDate: function (date) {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + (d.getDate() + 1)
      const year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [month, day, year].join('/');
    }
  },
  mounted () {
    this.getUserConventions()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.card-link {
  text-decoration:unset;
}
.card {
    font-family: helvetica;
}
.card-img-overlay {
  top: unset;
}

.card-title {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 20px;
}

.card-text {
  font-size: 16px;
}

.card-footer > h6 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.card-footer > p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
}

.flight-types {
  display: flex;
  max-width: 336px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 3px;
  color: #000;
  font-weight: bold;
}
.flight-types > input {
  display: none;
}
.flight-types > input:checked + label {
  color: #fff;
}
.flight-types > input:nth-of-type(1):checked ~ label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
}
.flight-types > input:nth-of-type(2):checked ~ label:last-of-type:before {
  transform: translateX(calc(100% + 0px));
}
.flight-types > input:nth-of-type(3):checked ~ label:last-of-type:before {
  transform: translateX(calc(200% + 0px));
}
.flight-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.flight-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(50% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
}
.flight-types label {
  padding: 6px 3px;
  margin-bottom: 0px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:before {
  background: #006bb6;
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:not(:last-child) {
  border-right: 1px solid #000;
}
</style>