<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-dialog" role="document" style="max-width: 750px;">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title">{{title}}</h5>
            <div class="d-flex">
              <button class="btn btn-secondary" @click="close">Cancel</button>
              <button class="btn btn-primary ml-3" @click="saveForm">Save</button>
            </div>
          </div>
          <div class="modal-body p-3" style="padding: 0px; text-align:left;">
            <div class="pb-3">
              <div class="row">
                <div v-for="fields in forms" :key="fields.id" :class="{'col-12': fields.col == 'col-12', 'col-6': fields.col == 'col-6'}">
                  <div v-if="fields.type == 'toggle-btn'">
                    <h4 style="font-weight: 500; font-size: 16px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                    <label class="switch">
                      <input type="checkbox" v-model="inputForm[fields.vmodel]">
                      <div class="slider round"></div>
                    </label>
                  </div>

                  <div v-if="fields.type == 'dropdown'">
                    <h4 style="font-weight: 500; font-size: 16px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                    <multiselect :options="fields.data" :allow-empty="true" :show-labels="false" :multiple="fields.multiple" :placeholder="fields.placeholder" v-model="inputForm[fields.vmodel]"></multiselect>
                  </div>

                  <div v-if="fields.type == 'textarea'">
                    <h4 style="font-weight: 500; font-size: 16px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                    <textarea :placeholder="fields.placeholder" class="form-control" v-model="inputForm[fields.vmodel]"></textarea>
                  </div>

                  <div v-if="fields.type == 'radio-btn'">
                    <h4 style="font-weight: 500; font-size: 16px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                    <b-form-radio-group v-model="inputForm[fields.vmodel]" :plain="fields.inline" :stacked="!fields.inline">
                      <b-form-radio v-for="(entry, index) in fields.data" :key="index" :value="entry">{{entry}}</b-form-radio>
                    </b-form-radio-group>
                  </div>

                  <div v-if="fields.type == 'checkbox'">
                    <h4 style="font-weight: 500; font-size: 16px; margin-bottom: 5px; margin-top:10px;">{{fields.label}}</h4>
                    <b-form-checkbox-group v-model="inputForm[fields.vmodel]" stacked>
                      <b-form-checkbox v-for="(entry, index) in fields.data" :key="index" :value="entry">{{entry}}</b-form-checkbox>
                    </b-form-checkbox-group>
                    <div v-if="fields.allow_other && hasOtherItem(inputForm[fields.vmodel])">
                       <textarea class="form-control" v-model="inputForm[fields.other_vmodel]"></textarea>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
import { http, authHeader } from '@/config/base-url'
export default {
  name: 'InteractionCompletedModal',
  props: ['id'],
  data () {
    return {
      forms: {},
      inputForm: {},
      title: ''
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    getForm: function () {
      this.title = JSON.parse(localStorage.getItem("settings") || '{}').data.completion_form.title
      this.forms = JSON.parse(localStorage.getItem("settings") || '{}').data.completion_form.fields
    },
    hasOtherItem: function (data) {
      if(data) {
        return data.includes('Other')
      }
      
    },
    saveForm: function () {
      console.log(this.inputForm)
      console.log('saved!');
      const items = {
        "is_completed": true,
        "completion_form": this.inputForm
      }
      console.log(items)
      http.put(`v2/conventions/interactions/${this.id}/completed`, items, {
        headers: authHeader
      }).then((res) => {
        console.log(res)
        this.inputForm = {}
        this.$emit('close')
      })

    }
  },
  mounted () {
    console.log(this.id)
    this.getForm()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vdp-datepicker input.form-control {
  background-color:#FFF;
}
</style>
<style scoped>

 .modal-mask {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-body {
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.modal-content {
  height: 500px;
}

.modal-title {
  font-size: 1.5em;
  padding-top: 5px;
}

.close:focus {
  outline: none;
}

/* Rounded sliders */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009370;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.custom__tag {
      display: inline-block;
    padding: 3px 12px;
    background: #f1f1f1;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
}

.option__title {
  padding-left: 5px;
}

.custom__remove {
  padding-left: 5px;
  cursor: pointer;
}

.multiselect__clear {
    position: absolute;
    right: 1px;
    padding-top: 8px;
    height: 50px;
    width: 50px;
    display: block;
    cursor: pointer;
    z-index: 2;
    color: #ccc;
}
</style>
